import React from "react";
import style from "./ArticleContent.module.less";

export default function ArticleContent(props) {

  return (
    <div className={style.article_content}>
      {/* 标题 */}
      {props.title ? (
        <div className={style.article_title}>{props.title}</div>
      ) : (
        ""
      )}

      {/* 副标题 */}
      {props.subtitle ? (
        <div className={style.subtitle_title}>{props.subtitle}</div>
      ) : (
        ""
      )}

      {/* ui li */}
      {
        props.list ? (
          <ul className={style.list_ul}>
            {
              props.list.map(item => {
                return (
                  <div className={style.list_index}>{item}</div>
                )
              })
            }
          </ul>
        ) : ""
      }

      {/* 内容 */}
      <div className={style.content}>
        {<div dangerouslySetInnerHTML={{ __html: props.text }}></div>}
      </div>

      {/* 图片 */}
      {props.img ? (
        <div className={style.content_img}>
          {/* <img src={props.img} style={{ width: "100%", height: "100%" }} /> */}
          {<div dangerouslySetInnerHTML={{ __html: props.img }}></div>}
        </div>
      ) : (
        ""
      )}

      {/* 小标题 */}
      {
        props.tips ? (

          <div className={style.tips_text}>{props.tips}</div>

        ) :''
      }

    </div>
  );
}
