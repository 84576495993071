import React from "react";

import { Breadcrumb } from "antd";
// import './MyBreadcrumb.module.less';

export default function MyBreadcrumb(props) {
  return (
    <Breadcrumb
      separator=">"
      items={props.routerList}
    />
  );
}
