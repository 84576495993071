export default {
    columns: [
        {
            title: "产品",
            key: "products",
            width: 260,
            render: (_, { products }) => (
                <div
                    style={{
                        width: "100px",
                        height: "80px",
                        border: "1px solid #dddddd",
                    }}
                >
                    <img src={products} style={{ width: '100%', height: '100%', }} />
                </div>
            ),
        },
        {
            title: "文件名称",
            dataIndex: "fileName",
            key: "fileName",
            width:300,
        },
        {
            title: "版本",
            width: 100,
            dataIndex: "version",
            key: "version",
            fixed: "left",
        },
        {
            title: "描述",

            dataIndex: "describe",
            key: "describe",
            fixed: "left",
        },
        {
            title: '操作',
            dataIndex: '',
            key: 'x',
            width:120,
            render: () => <a>下载</a>,
        },
    ],
    dataSource: [
        {
            products:'/img/developing_software.png',
            fileName: 'YINS_Touch_SW_V',
            version: 'V1.05',
            describe: '触摸调参软件',
        },
        {
            products:'/img/developing_software.png',
            fileName: 'YINSDrvInstall',
            version: 'V1.0',
            describe: 'keil仿真插件软件',
        },
        {
            products:'/img/developing_software.png',
            fileName: 'Yins-C51Tool',
            version: 'V0.0.3',
            describe: 'Yins MCU用户参数配置、程序烧录软件',
        },
    ],
}