import axios, { InternalAxiosRequestConfig } from 'axios'

// axios.defaults.timeout = 3000;
// axios.defaults.baseURL = 'http://192.168.1.203:8080';
axios.defaults.baseURL = '/api'
axios.defaults.headers = {
    "Content-Type": "application/json",
} as any;

// 请求拦截
axios.interceptors.request.use(
    (config: any) => {
        
        // if (config.url.indexOf('token') > 0 || config.url.indexOf('captcha') > 0) { //登录接口进行切换
        //     config.headers = { ...config.headers, Authorization: 'Basic YWRtaW46MTIzNDU2' }
        // } else {
        //     config.headers = { ...config.headers, Authorization: `Bearer ${sessionStorage.getItem('token')}` }
        // }

        return config
    },
    (error) => {
        return Promise.reject(error);
    }
)


// 响应拦截
axios.interceptors.response.use(
    (response: any) => {
        return response;
    },
    (err) => {
        // 登录过期
        // if (err.request.status == 401) {
        //     sessionStorage.clear();
        //     window.location.href = '/login';
        // }
        return Promise.reject(err);
    }
)

export default axios;
