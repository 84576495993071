import React from "react";
import { Space, Table, Tag } from 'antd';

export default function FileTable(props) {
  return (
    <div >
        <span style={{
            fontWeight: 'normal',
            fontSize: '22px',
            color: '#919191',
        }}>{props.title}</span>
        <Table
          bordered={true} 
          style={{marginTop:'10px'}} 
          columns={props.columns} dataSource={props.dataSource} 
          pagination={false} />
    </div>
  )
}
