import { SET_TOKEN , SET_USE_INFO } from '../../mutation'

// 存储token
export function tokenReducer(preState = '' , action)  {
    const { data , type } = action;

    // 保存token 这里不叫较真安全性 直接放置在sessionStorage中
    if(data !== undefined && type === SET_TOKEN) sessionStorage.setItem('token' , data);

    return sessionStorage.getItem('token') || preState;
}

// 存储个人信息
export function useInfoReducer (preState = {} , action ) {

    const { data , type } = action;

    if(type === SET_USE_INFO){
        preState = data;
        sessionStorage.setItem('useInfo' , JSON.stringify(data));
        return preState;
    }

    return JSON.parse(sessionStorage.getItem('useInfo')) || preState
}