export default {
    columns: [
        {
            title: "文件名称",
            dataIndex: "fileName",
            key: "fileName",
            fixed: "left",
        },
        {
            title: "版本",
            width: 100,
            dataIndex: "version",
            key: "version",
            fixed: "left",
        },
        {
            title: "描述",

            dataIndex: "describe",
            key: "describe",
            fixed: "left",
        },
        {
            title: '操作',
            dataIndex: '',
            key: 'x',
            render: () => <a>下载</a>,
        },
    ],
    dataSource: [
        {
            fileName: 'YINS8S051x8x7_sample',
            version: 'V1.0',
            describe: '包含YINS8S051x8x7芯片模块的驱动程序和样例程序',
        },
        {
            fileName: 'YINS8S051x_FWLib',
            version: 'V1.0',
            describe: '包含YINS8S051x固件开发库',
        },
    ],
}