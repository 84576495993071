import React, { useEffect, useState } from "react";
import { Table, Button, Modal } from "antd";
import type { TableColumnsType } from "antd";
import { createStyles } from "antd-style";
import style from "./ChipTable.module.less";

import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import data from "./chip.js";

import { donwnload } from '@/utils/tool';

const useStyle = createStyles(({ css, token }) => {
  const { antCls } = token as any;
  return {
    customTable: css`
      ${antCls}-table {
        ${antCls}-table-container {
          ${antCls}-table-body,
          ${antCls}-table-content {
            scrollbar-width: thin;
            scrollbar-color: unset;
          }
        }
      }
    `,
  };
});

const Row = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: "move",
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
};

export default function ChipTable() {
  const { styles } = useStyle();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const columns2 = [
    {
      title: "字段",
      dataIndex: "title",
    },
  ];

  const [dataSource2, setDataSource2] = useState(data.columns);

  // 多选框
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  let source = [];

  // 判断缓存中是否存在
  let chipList = localStorage.getItem("chipList");
  if (chipList) {
    source = JSON.parse(chipList);
  } else {
    source = data.columns;
  }

  // 点击自定义列
  const customize = () => {
    // 初始化选中效果
    if (chipList) {
      const arr = JSON.parse(chipList)
        .filter((item) => !item.hidden)
        .map((item) => item.key);
      setSelectedRowKeys(arr);
    } else {
      // 默认是所有选中效果
      const arr = data.columns.map((item) => item.key);
      setSelectedRowKeys(arr);
    }
    setIsModalOpen(true);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    })
  );

  // 移动顺序
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource2((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  // 提交
  const handleOk = () => {
    const arr = dataSource2.map((item) => {
      if (selectedRowKeys.indexOf(item.key) === -1) {
        item["hidden"] = true;
      } else {
        item["hidden"] = false;
      }
      return item;
    });

    localStorage.setItem("chipList", JSON.stringify(arr));
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // 下载
  const handleDown = () => {
    donwnload('/file/芯片系列.xls' , '芯片系列' , '');
  }

  return (
    <div className={style.chip_table_wrap}>
      <div
        style={{
          textAlign: "right",
          margin: "10px 0",
        }}
      >
        <Button
          style={{ marginRight: "10px" }}
          color="primary"
          onClick={customize}
        >
          自定义列
        </Button>
        <Button type="primary" onClick={handleDown}>下载</Button>
      </div>
      <Table
       bordered={true} 
        className={styles.customTable}
        columns={source as any}
        dataSource={data.dataSource}
        scroll={{ x: "max-content"}}
        pagination={false}
      />
      <Modal
        title="自定义列"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className={style.modal_wrap}
        okText="应用"
        cancelText="取消"
      >
        <ul>
          <li>说明:</li>
          <li>1.可通过勾选、取消勾选复选框以显示/隐藏列数据</li>
          <li>2.通过拖拽调整左右列顺序</li>
        </ul>
        <DndContext
          sensors={sensors}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={onDragEnd}
        >
          <SortableContext
            items={dataSource2.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              rowSelection={rowSelection}
              components={{
                body: { row: Row },
              }}
              rowKey="key"
              columns={columns2}
              dataSource={dataSource2}
              pagination={false}
              style={{height:'60vh' , overflow:'auto'}}
            />
          </SortableContext>
        </DndContext>
      </Modal>
    </div>
  );
}
