import React from "react";
import { Pagination } from "antd";
import style from "./News.module.less";
import { useNavigate } from "react-router-dom";

import data from '@/static/json/news.js'

export default function News() {

  const navigate = useNavigate();

  const handleNews = (id) => {
    navigate("/article", {
      state:{
        id
      }
    });
  };

  return (
    <>
      <div className={style.illustrations}>
        <img
          style={{ width: "100%", height: "100%" }}
          src="/img/news_top.jpg"
          alt=""
        />
      </div>
      <div className={style.news_wrap}>
        {data.map((item, index) => {
          return (
            <div key={item.id} className={style.news_index} onClick={() =>handleNews(item.id)}>
              <div className={style.new_img}>
                <img style={{width:'100%',height:'100%'}} src={item.img} alt="" />
              </div>
              {/* new_profile 蓝色 profile_ash 灰色 */}
              <div className={[style.new_profile].join(" ")}>
                <div className={style.new_date}>
                  <div className={style.new_year}>{item.year}</div>
                  <div className={style.new_day}>{item.months}</div>
                </div>
                <div className={style.new_text}>
                  <p className={style.news_title}>
                    {item.title}
                  </p>
                  <p className={style.news_text}>
                    {item.profile}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* 分页 */}
      {/* <div className={style.pagination}>
        <Pagination defaultCurrent={1} total={50} />
      </div> */}
    </>
  );
}
