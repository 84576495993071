import axios from '../utils/axios'

export default {
    // 获取邮件token
    getMailToken(params) {
        return axios({
            baseURL: 'https://alimail-cn.aliyuncs.com',
            url: '/oauth2/v2.0/token',
            method: 'POST',
            params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
    },

    // 创建草稿
    getMailMessages(data, email, token) {
        return axios({
            baseURL: 'https://alimail-cn.aliyuncs.com',
            url: `/v2/users/${email}/messages`,
            method: 'POST',
            data,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
    },


    // 发送
    send(email, token, id) {
        return axios({
            baseURL: 'https://alimail-cn.aliyuncs.com',
            url: `/v2/users/${email}/messages/${id}/send`,
            method: 'POST',
            data: {
                saveToSentItems: true,
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    // 创建上传会话
    /**
     * 
     * @param email 
     * @param id 
     * @param token 
     * @param data {
            name string 附件名
            contentId string 附件cid
            isInline boolean 是否为内联附件
            extHeaders object 附件扩展头
        }
     * @returns 
     */
    createUploadSession(email, id, token, data) {
        return axios({
            baseURL: 'https://alimail-cn.aliyuncs.com',
            url: `/v2/users/${email}/messages/${id}/attachments/createUploadSession`,
            method: 'POST',
            data,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
    },


    // 附件上传
    annexUpload(url, token, data) {
        return axios({
            baseURL: 'https://alimail-cn.aliyuncs.com',
            url,
            method: 'POST',
            data,
            headers: {
                'Content-Type': 'application/octet-stream',
                'Authorization': `Bearer ${token}`,
            }
        })
    }

}