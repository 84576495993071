import React, { useState } from "react";
import style from "./PrivacyAgreement.module.less";
import { Button, Modal } from "antd";

export default function PrivacyAgreement() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null);

  const viewAgreement = (type) => {
    setIsModalOpen(true);
    if (type === "服务") {
      setFile("/file/英思集成网站服务协议.pdf");
    } else {
      setFile("/file/英思集成隐私政策.pdf");
    }
  };
  
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={style.privacy_agreement}>
        提交申请即代表同意
        <span className={style.highlight} onClick={() => viewAgreement("服务")}>
          《服务协议》
        </span>
        与
        <span className={style.highlight} onClick={() => viewAgreement("隐私")}>
          《隐私政策》
        </span>
      </div>
      <Modal
        title=" "
        open={isModalOpen}
        width={"60vw"}
        onCancel={handleCancel}
        footer={null}
      >
        <div style={{ height: "70vh" }}>
          <iframe
            src={file}
            style={{
              width: "100%",
              height: "100%",
              padding: "15px 0",
              boxSizing: "border-box",
            }}
          ></iframe>
        </div>
      </Modal>
    </>
  );
}
