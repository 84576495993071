import React, { useEffect, useState } from "react";
import ArticleTitle from "@/component/ArticleTitle/ArticleTitle";
import style from "./Article.module.less";
import ArticleContent from "@/component/ArticleContent/ArticleContent";
import NextArticle from "@/component/NextArticle/NextArticle";
import HotArticles from "@/component/HotArticles/HotArticles";
import MyBreadcrumb from "@/component/MyBreadcrumb/MyBreadcrumb";
import { useLocation } from "react-router-dom";
import data from "@/static/json/article.js";

export default function Article() {

  let routerList = [
    {
      title: "首页",
      href: "/index",
    },
    {
      title: "新闻动态",
      href: "/news",
    },
    {
      title: "新闻详情",
      href: "/article",
      className: "highlight",
    },
  ];

  const { state } = useLocation();

  const fileItem = data.find((item) => state.id == item.id);

  return (
    <>
      <div className={style.illustrations}>
        <img
          style={{ width: "100%", height: "100%" }}
          src="/img/news_top.jpg"
        />
      </div>

      <div className={style.article_wrap}>

        <div className={style.breadcrumb}>
          <MyBreadcrumb routerList={routerList} />
        </div>

        <ArticleTitle  {...fileItem}  />

        {
          fileItem.content.map(item => {
            return (
              <div key={item.id} >
              <ArticleContent {...item} />
              </div>
            )
          })
        }

        <div
          style={{
            marginBottom: "100px",
          }}
        >
          <NextArticle />
        </div>

        <HotArticles />
      </div>
    </>
  );
}
