import React, { useEffect, useMemo, useState } from "react";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import style from "./NavigationBar.module.less";

type MenuItem = Required<MenuProps>["items"][number];

const items: MenuItem[] = [
  {
    label: "首页",
    key: "/index",
  },
  {
    label: "芯片产品",
    key: "/chipProducts",
  },
  {
    label: "教学产品",
    key: "/introduce",
  },
  {
    label: "关于英思",
    key: "/yins",
    children: [
      { key: "/Company", label: "公司介绍" },
      { key: "/aboutYins", label: "公司历程" },
      { key: "/glory", label: "公司荣誉" },
    ],
  },
  {
    label: "新闻动态",
    key: "/news",
  },
  {
    label: "加入我们",
    key: "/agents",
  },
];

const NavigationBar = () => {
  const [current, setCurrent] = useState("/index");

  const navigate = useNavigate();

  const location = useLocation();

  // useEffect(() => {
  //   setCurrent(location.pathname);
  // }, [location.pathname]);

  const result = useMemo(() => {
    return location.pathname;
  }, [location.pathname]);

  const onClick: MenuProps["onClick"] = (e) => {
    navigate(e.key);
  };

  return (
    <>
      <Menu
        onClick={onClick}
        selectedKeys={[result]}
        mode="horizontal"
        items={items}
        className={style.menu_wrap}
      />
    </>
  );
};

export default NavigationBar;
