import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Modal, Button, Checkbox, Form, Input } from "antd";
import mailApi from "@/api/mail";
import PrivacyAgreement from '@/component/PrivacyAgreement/PrivacyAgreement'

const TryOutForm = forwardRef((props, ref) => {
  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleTryOutShow = () => {
    setIsModalOpen(true);
  };

  const onSubmit = (values) => {
    let text = `
    <p>企业名称：${values.companyName}</p>
    <p>姓名：${values.userName}</p>
    <p>电话：${values.phone}</p>
    <p>企业邮箱：${values.enterpriseMailbox}</p>
  `;

  // 创建草稿
    mailApi
      .getMailToken({
        grant_type: "client_credentials",
        client_id: "cZof0XCwQMdFR4zO",
        client_secret:
          "ZzKCMhDSCar5AfFo7J5DUQkyKHNt4cT8amPChqDFBGjGI0parGCjyQAZjC3UHPAv",
      })
      .then((res) => {
        // 创建要发送的会话
        mailApi
          .getMailMessages(
            {
              message: {
                internetMessageId: "",
                subject: "英思芯学实践一体机",
                summary: "摘要",
                priority: "PRY_HIGH",
                isReadReceiptRequested: false,
                from: {
                  email: "webmailer@ins-ic.com",
                  name: "webmailer@ins-ic.com",
                },
                toRecipients: [
                  {
                    email: "info@ins-ic.com",
                    name: "info@ins-ic.com",
                  },
                ],
                ccRecipients: [
                  {
                    email: "",
                    name: "",
                  },
                ],
                bccRecipients: [
                  {
                    email: "",
                    name: "",
                  },
                ],
                replyTo: [
                  {
                    email: "",
                    name: "",
                  },
                ],
                body: {
                  bodyText: "",
                  bodyHtml: text,
                },
                internetMessageHeaders: {
                  property1: "",
                  property2: "",
                },
                tags: [""],
              },
            },
            "webmailer@ins-ic.com",
            res.data.access_token
          )
          .then((reslove) => {
            setIsModalOpen(false);
            mailApi
              .send(
                "webmailer@ins-ic.com",
                res.data.access_token,
                reslove.data.message.id
              )
              .then(() => {
                form.resetFields();
                Modal.info({
                  title: "提交成功",
                  content: (
                    <div>
                      <p>您的申请已提交！</p>
                      <p>平台登录方式将会在10个工作日内发送至您的邮箱，请注意查收。</p>
                    </div>
                  ),
                });
              });
          });
      });
  };

  useImperativeHandle(ref, () => ({
    handleTryOutShow,
  }));

  return (
    <Modal
      title="免费试用"
      width="600px"
      open={isModalOpen}
      destroyOnClose
      footer={null}
      onCancel={handleCancel}
      //   onOk={handleOk}
    >
      <Form
        name="basic"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        form={form}
        onFinish={onSubmit}
      >
        <Form.Item
          label="您的学校或企业名称"
          name="companyName"
          labelCol={{ span: 24 }}
          rules={[{ required: true, message: "请输入学校或企业名称" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="您的姓名"
          name="userName"
          labelCol={{ span: 24 }}
          rules={[{ required: true, message: "请输入您的姓名" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={
            <span>
              您的学校/企业邮箱
              <span
                style={{
                  fontSize: "12px",
                  color: "#d9001b",
                }}
              >
                （平台仅向学校和企业开放，请勿填写个人邮箱）
              </span>
            </span>
          }
          name="enterpriseMailbox"
          labelCol={{ span: 24 }}
          rules={[{ required: true, message: "请输入企业邮箱" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="您的手机号码"
          name="phone"
          labelCol={{ span: 24 }}
          rules={[{ required: true, message: "请输入手机号码" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          <Button type="primary" htmlType="submit">
            立即提交申请
          </Button>
        </Form.Item>
      </Form>

      <PrivacyAgreement></PrivacyAgreement>

    </Modal>
  );
});

export default TryOutForm;
