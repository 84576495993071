export default {
    columns: [
        {
            title: "产品",
            key: "products",
            width: 260,
            // render: (_, {products}) => <a>{products}</a>,
            render: (_ , {products}) => (
                <div
                    style={{
                        width: "100px",
                        height: "80px",
                        border: "1px solid #dddddd",
                    }}
                >
                    <img src={products} style={{ width:'100%', height:'100%',}} />
                </div>
            ),
        },
        {
            title: "文件名称",
            dataIndex: "fileName",
            key: "fileName",
            width:300,
        },
        {
            title: "版本",
            width: 100,
            dataIndex: "version",
            key: "version",
            fixed: "left",
        },
        {
            title: "描述",

            dataIndex: "describe",
            key: "describe",
            fixed: "left",
        },
        {
            title: '操作',
            dataIndex: '',
            key: 'x',
            width:120,
            render: () => <a>下载</a>,
        },
    ],
    dataSource: [
        {
            products:'/img/emulator.png',
            fileName: '仿真器',
            version: 'V1.0',
            describe: '仿真工具',
        },
        {
            products:'/img/developing_software.png',
            fileName: '脱机烧录器',
            version: 'V2.0',
            describe: '脱机烧录工具',
        },
    ],
}