import React, { useEffect, useState } from "react";
import Titlebox from "@/component/Titlebox/Titlebox";
import Productrecommendation from "./Productrecommendation";
import News from "./News";
import Partners from "./Partners";
import Consult from "./Consult";
import style from "./IndexPage.module.less";

export default function IndexPage() {
  const [products, setProducts] = useState({
    leftImg: false,
    rightImg: false,
  });

  useEffect(() => {
    window.addEventListener("scroll", listenScroll);
  }, []);

  const listenScroll = (event) => {
    let scrollTop = document?.documentElement.scrollTop;
    productsImgMove(scrollTop);
  };

  // 产品图片移动
  const productsImgMove = (scrollTop) => {
    if(scrollTop < 100){
      setProducts({
        leftImg: false,
        rightImg: false,
      });
    }
    if (scrollTop > 200) {
      setProducts({
        leftImg: true,
        rightImg: false,
      });
    } 
    if (scrollTop > 800) {
      setProducts({
        leftImg: true,
        rightImg: true,
      });
    } 
  };

  return (
    <>
      {/* 插画 */}
      <div className={style.illustrations}>

        <div className={style.bubble_1}>
          <img src="/img/bubble_content_1.png" style={{width:'70%',height:'75%'}} />
        </div>
        <div className={style.bubble_2}>
          <img src="/img/bubble_content_2.png" style={{width:'65%',height:'60%'}}  />
        </div>
        <div className={style.bubble_3}>
          <img src="/img/bubble_content_3.png" />
        </div>
        <div className={style.bubble_4}>
          <img src="/img/bubble_content_4.png" style={{width:'75%',height:'75%'}}  />
        </div>
        <img style={{width:'100%',height:'100%'}} src='/img/index_top.jpg' />
      </div>

      <Titlebox titleName="产品推荐">
        <Productrecommendation show={products}></Productrecommendation>
      </Titlebox>

      <Titlebox titleName="新闻动态">
        <News />
      </Titlebox>

      <Titlebox titleName="合作伙伴" customBackground="#e1ecfb">
        <Partners />
      </Titlebox>

      <Titlebox titleName="联系我们">
        <Consult />
      </Titlebox>
    </>
  );
}
