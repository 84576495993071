import React from "react";
import style from "./Glory.module.less";

export default function Glory() {
  const data = [
    "/img/glory_1.jpg",
    "/img/glory_2.jpg",
    "/img/glory_3.jpg",
    "/img/glory_4.jpg",
  ];

  return (
    <div>
      <div className={style.illustrations}>
        <img
          src="/img/illustrations_top.jpg"
          style={{ width: "100%", height: "100%" }}
          alt=""
        />
      </div>

      {data.map((item) => {
        return (
          <div className={style.glory_index} key={item}>
            <img
              src={item}
              style={{ width: "100%", height: "100%" }}
              alt=""
            />
          </div>
        );
      })}
    </div>
  );
}
