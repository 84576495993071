import React from "react";
import { Carousel } from "antd";
import style from './IndexPage.module.less';

export default function Partners() {
  const onChange = (currentSlide: number) => {
    console.log(currentSlide);
  };

  let arr = [
    '/img/index_college_1.jpg',
    '/img/index_college_2.jpg',
    '/img/index_college_3.jpg',
    '/img/index_college_4.jpg',
    '/img/index_college_5.jpg',
    '/img/index_college_6.jpg',
    '/img/index_college_7.jpg',
    '/img/index_college_8.jpg',
    '/img/index_college_9.jpg',
    '/img/index_college_10.jpg',
  ];

  return (
    <div className={style.partners_wrap}>
      <Carousel afterChange={onChange}>
        <div>
          <div className={style.carousel_index}>
            {arr.map((item) => {
              return (
                <div key={item} className={style.box_index}>
      
                  <img src={item} style={{width:'100%',height:'100%'}} alt="" />

                  {/* <div className={style.box_img}></div> */}
                  {/* <div className={style.box_text}>北京理工大学珠海学院</div> */}
                </div>
              );
            })}
          </div>
        </div>
      </Carousel>
    </div>
  );
}
