import React from "react";
import style from './Company.module.less';

export default function Company() {
  return (
    <div className={style.company_wrap}>
      <div className={style.illustrations}>
        <img style={{width:'100%', height:'100%'}} src="/img/company_top.jpg" alt="" />
      </div>
      <div className={style.company_details}>
        <div className={style.company_icon}>
          <img style={{width:'100%', height:'100%'}} src="/img/company_icon.png" alt="" />
        </div>
        <div className={style.company_inner}>
          <div className={style.company_title}>珠海市英思集成电路设计有限公司</div>
          <div className={style.gather}>
              珠海市英思集成电路设计有限公司是一家致力于人体静电感应技术的工业级集成电路设计型企业，由陈大同先生、王洪魁先生、珠海一微半导体股份有限公司、广东省科学院珠海产业技术研究院以及多位跨行业经营者完成早期投资。
              {/* <p>珠海市英思集成电路设计有限公司是一家致力于人体静电感应技术的工业级集成电路设计型企业， </p>
              <p>由陈大同先生、王洪魁先生、珠海一微半导体股份有限公司、</p>
              <p>广东省科学院珠海产业技术研究院以及多位跨行业经营者完成早期投资。</p> */}
          </div>
          <div className={style.gather}>
          公司的主营业务为人体静电感应触控芯片的研发和销售，
          产品具备高集成度、高性价比、易开发的特点，
          帮助客户优化成本并满足多样化的需求。
              {/* <p>公司的主营业务为人体静电感应触控芯片的研发和销售， </p>
              <p>产品具备高集成度、高性价比、易开发的特点，</p>
              <p>帮助客户优化成本并满足多样化的需求。</p> */}
          </div>
          <div className={style.gather}>
          公司围绕自主芯片技术建立多赛道产品线，除了人体静电感应触控芯片外，
          推出业界首款芯片全流程实践教学平台，满足高校最后一公里教学需求。
              {/* <p>公司围绕自主芯片技术建立多赛道产品线，除了人体静电感应触控芯片外，</p>
              <p>推出业界首款芯片全流程实践教学平台，满足高校最后一公里教学需求。</p> */}
          </div>
          <div className={style.gather}>
          未来，公司将挖掘芯片技术的商业价值，持续推出具有市场竞争力的产品，
          拓展更广泛的应用领域和客户覆盖范围，致力于成为家电领域具影响力的集成电路设计公司。
              {/* <p>未来，公司将挖掘芯片技术的商业价值，持续推出具有市场竞争力的产品，</p>
              <p>拓展更广泛的应用领域和客户覆盖范围，致力于成为家电领域具影响力的集成电路设计公司。</p> */}
          </div>
        </div>
      </div>
      <div className={style.company_address}>
        <div className={style.company_address_inner}>
          <img style={{width:'100%', height:'100%'}} src="/img/company_address.jpg" alt="" />
        </div>
      </div>
    </div>
  );
}
