import React, { useRef, useState } from "react";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Carousel } from "antd";
import style from "./Introduce.module.less";

const contentStyle: React.CSSProperties = {
  margin: 0,
  height: "570px",
  color: "#fff",
  // lineHeight: '160px',
  textAlign: "center",
  background: "#364d79",
};

export default function PracticeIntroduction() {
  const [index, setIndex] = useState(0);

  const carouselRef = useRef(null);

  let arr = [
    "/img/practice_1.jpg",
    "/img/practice_2.jpg",
    "/img/practice_3.jpg",
  ];

  const marqueeInner = useRef();

  const handleChangePage = (code) => {
    // 总
    let sum = 0;
    // 阈值
    let threshold = 1320;
    let currentIndex = index;
    let dom = marqueeInner.current as HTMLElement;
    if (code === "left") {
      if (currentIndex === arr.length - 1) {
        currentIndex = 0;
      } else {
        currentIndex = currentIndex + 1;
      }
      sum = threshold * -currentIndex;
    } else if (code === "right") {
      if (currentIndex === 0) {
        currentIndex = arr.length - 1;
      } else {
        currentIndex = currentIndex - 1;
      }

      sum = threshold * -currentIndex;
    }
    dom.style.left = `${sum}px`;
    setIndex(currentIndex);
  };

  const changeCarousel = (type) => {
    const dom = carouselRef.current;
    if (type === "left") {
      dom.prev();
    } else if (type === "right") {
      dom.next();
    }
  };

  return (
    <div className={style.practice_introduction}>
      <div className={style.practice_title_wrap}>
        <div className={style.title_inner}>
          <div>
            <div className={style.title}>英思芯学实践系统</div>
            <div className={style.title_small}>
              “实践+教管+考试”的立体教学辅助系统
            </div>
          </div>
          {/* <div className={style.arrow_wrap}>
            <div
              className={style.arrow_index}
              onClick={() => handleChangePage("right")}
            >
              <LeftOutlined className={style.arrow_icon} />
            </div>
            <div
              className={style.arrow_index}
              onClick={() => handleChangePage("left")}
            >
              <RightOutlined className={style.arrow_icon} />
            </div>

          </div> */}
        </div>
      </div>

      <div className={style.practice_content}>
        <div
          className={style.arrow_left}
          onClick={() => changeCarousel("left")}
        >
          <LeftOutlined />
        </div>
        <div
          className={style.arrow_right}
          onClick={() => changeCarousel("right")}
        >
          <RightOutlined />
        </div>

        <Carousel
          autoplay
          autoplaySpeed={8000}
          infinite={false}
          ref={carouselRef}
        >
          {arr.map((item) => {
            return (
              <div key={item}>
                <div style={contentStyle}>
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    src={item}
                  />
                </div>
              </div>
            );
          })}
        </Carousel>

        {/* <div className={style.marquee}>
          <div className={style.marquee_inner_wrap} ref={marqueeInner}>
            {arr.map((item, index) => {
              return (
                <div className={style.marquee_index} key={index}>
                  <div className={style.marquee_inner}>
                    <img style={{width:'100%', height:'100%'}} src={item} alt="" />
                  </div>
                </div>
              );
            })}
          </div>
        </div> */}
      </div>
    </div>
  );
}
