export default {
    columns: [
        {
            title: "文件名称",
            dataIndex: "fileName",
            key: "fileName",
            fixed: "left",
        },
        {
            title: "版本",
            width: 100,
            dataIndex: "version",
            key: "version",
            fixed: "left",
        },
        {
            title: "描述",

            dataIndex: "describe",
            key: "describe",
            fixed: "left",
        },
        {
            title: '操作',
            dataIndex: '',
            key: 'x',
            render: () => <a>下载</a>,
        },
    ],
    dataSource: [
        {
            fileName: 'YINS8S051x8x7用户手册',
            version: 'V0.1',
            describe: 'YINS8S051x8x7芯片特性、性能指标、封装信息等',
        },
        {
            fileName: '触摸调参软件使用说明',
            version: 'V1.1',
            describe: '介绍如何使用触摸调参软件',
        },
        {
            fileName: 'YINS8开发平台使用说明',
            version: 'V1.0',
            describe: 'YINS8芯片keil插件安装说明',
        },
    ],
}