import React from "react";
// import '@/pages/home/Index.less';
import "./Index.less";

import NavigationBar from "@/component/NavigationBar/NavigationBar";

import { Outlet, useLocation } from "react-router-dom";

import { CSSTransition, SwitchTransition } from "react-transition-group";

export default function Index() {
  const location = useLocation();

  // 顶部导航栏
  const TopNav = () => {
    return (
      <div className="top_nav">
        <div className="nav_logo">
          <img
            style={{ width: "100%", height: "100%" }}
            src="/img/logo_icon.png"
            alt=""
          />
        </div>
        <div className="nav">
          <NavigationBar />
        </div>
      </div>
    );
  };

  // 底部导航
  const BottomNav = () => {
    return (
      <div className="bottom_nav">
        Copyright © 2021 - 2024 珠海市英思集成电路设计有限公司 <span style={{marginLeft:'5px'}}>版权所有</span>
        <a style={{color:'#fff',marginLeft:'20px' , textDecoration: 'none'}} target="_blank"  href="https://beian.miit.gov.cn/">备案号：粤ICP备2023080493号-1</a>
      </div>
    );
  };

  return (
    <div className="skeleton">
      <TopNav />
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={location.pathname}
          appear={true}
          timeout={400}
          classNames="fade"
          unmountOnExit
        >
          <Outlet />
        </CSSTransition>
      </SwitchTransition>
      <BottomNav />
    </div>
  );
}
