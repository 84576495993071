import { Suspense, lazy } from "react";
// import { useRoutes, Navigate } from "react-router-dom";
// import Login from "../pages/Login/Login";
// import InnerHtml from "../pages/InnerHtml/InnerHtml";
// import HomePage from "../pages/HomePage/HomePage";

import Index from "@/pages/Index/Index";
import IndexPage from "@/pages/IndexPage/IndexPage";
import Introduce from "@/pages/Introduce/Introduce";
import News from "@/pages/News/News";
import Article from "@/pages/Article/Article";
import Company from "@/pages/AboutCompany/Company";
import AboutYins from "@/pages/AboutYins/AboutYins";
import Agents from "@/pages/Agents/Agents";
import Glory from "@/pages/Glory/Glory";
import ChipProducts from '@/pages/ChipProducts/ChipProducts'
import { Navigate } from "react-router-dom";

const laztRoutes = (element) => {
  // return <Suspense fallback={<div>Loading...</div>}>
  //      {element}
  // </Suspense>
  return <>{element} </>;
};

const rootRouter: any[] = [
  {
    path: "/",
    element: <Index />,
    // auth: true,
    // exact: true,
    // redirect :'/index',
    children: [
      {
        path: "",
        element: <Navigate to="/index" />,
      },
      {
        path: "/index",
        element: laztRoutes(<IndexPage />),
      },
      {
        path:'/chipProducts',
        element: laztRoutes(<ChipProducts />),
        meta:{
          title:'芯片产品',
        }
      },
      {
        path: "/introduce",
        element: laztRoutes(<Introduce />),
        meta:{
          title:'教学产品',
        }
      },
      {
        path: "/news",
        element: laztRoutes(<News />),
        meta:{
          title:'新闻动态',
        }
      },
      {
        path: "/article",
        element: laztRoutes(<Article />),
        meta:{
          title:'新闻文章',
        }
      },
      {
        path: "/company",
        element: laztRoutes(<Company />),
        meta:{
          title:'新闻文章',
        }
      },
      {
        path: "/aboutYins",
        element: laztRoutes(<AboutYins />),
        meta:{
          title:'关于英思',
        }
      },
      {
        path:'/agents',
        element: laztRoutes(<Agents/>),
        meta:{
          title:'代理商申请',
        }
      },
      {
        path:'/glory',
        element: laztRoutes(<Glory />),
        meta:{
          title:'公司荣誉'
        }
      }
    ],
  },
];


export default rootRouter;
