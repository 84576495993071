import React, { useState } from "react";
import style from "./Agents.module.less";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import {
  Modal,
  Space,
  Button,
  Radio,
  Form,
  Input,
  message,
  Upload,
} from "antd";
import mailApi from "@/api/mail";
import PrivacyAgreement from '@/component/PrivacyAgreement/PrivacyAgreement'

const { TextArea } = Input;

export default function Agents() {
  const [form] = Form.useForm();

  const [file, setFile] = useState<any>();

  const [loadings, setLoadings] = useState<boolean>(false);

  const props: UploadProps = {
    name: "file",
    action: "#",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload() {
      return false;
    },
    onChange(info) {
      setFile(info.file);
    },
  };

  const onSubmit = (values) => {
    setLoadings(true);
    let text = `
    <p>代理商类型：${
      values.agents === 1 ? "芯片产品代理商" : "教学产品代理商"
    }</p>
    <p>姓名：${values.username}</p>
    <p>职位：${values.positions}</p>
    <p>公司/机构名称：${values.institutionName}</p>
    <p>公司/机构主营业务：${values.mainBusiness}</p>
    <p>邮箱：${values.mailbox}</p>
    <p>电话：${values.phone}</p>
    <p>留言：${values.message}</p>
    `;

    /**
     * agents 代理商类型
     * username 您的姓名
     * positions 职位
     * institutionName 公司/机构名称
     * mainBusiness  公司/机构主营业务
     * organization  公司/机构介绍
     * mailbox 邮箱
     * phone
     * message 留言
     */

    mailApi
      .getMailToken({
        grant_type: "client_credentials",
        client_id: "cZof0XCwQMdFR4zO",
        client_secret:
          "ZzKCMhDSCar5AfFo7J5DUQkyKHNt4cT8amPChqDFBGjGI0parGCjyQAZjC3UHPAv",
      })
      .then((res) => {
        mailApi
          .getMailMessages(
            {
              message: {
                internetMessageId: "",
                subject: "代理商申请",
                summary: "摘要",
                priority: "PRY_HIGH",
                isReadReceiptRequested: false,
                from: {
                  email: "webmailer@ins-ic.com",
                  name: "webmailer@ins-ic.com",
                },
                toRecipients: [
                  {
                    email: "info@ins-ic.com",
                    name: "info@ins-ic.com",
                  },
                ],
                ccRecipients: [
                  {
                    email: "",
                    name: "",
                  },
                ],
                bccRecipients: [
                  {
                    email: "",
                    name: "",
                  },
                ],
                replyTo: [
                  {
                    email: "",
                    name: "",
                  },
                ],
                body: {
                  bodyText: "1234",
                  bodyHtml: text,
                },
                internetMessageHeaders: {
                  property1: "",
                  property2: "",
                },
                tags: [""],
              },
            },
            "webmailer@ins-ic.com",
            res.data.access_token
          )
          .then((reslove) => {
            if (!file) {
              mailApi
                .send(
                  "webmailer@ins-ic.com",
                  res.data.access_token,
                  reslove.data.message.id
                )
                .then(() => {
                  setLoadings(false);
                  tips();
                  form.resetFields();
                });
            } else {
              // 创建上传会话
              mailApi
                .createUploadSession(
                  "webmailer@ins-ic.com",
                  reslove.data.message.id,
                  res.data.access_token,
                  {
                    attachment: {
                      name: file.name,
                      contentId: "7a49d467-7079-43b3-8e26-3b9251b340e8",
                      isInline: true,
                      extHeaders: {
                        property1: "测试1",
                        property2: "测试2",
                      },
                    },
                  }
                )
                .then((res2) => {

                  // uploadFile(res2.data.uploadUrl, res.data.access_token).then(
                  //   () => {
                  //     mailApi
                  //       .send(
                  //         "webmailer@ins-ic.com",
                  //         res.data.access_token,
                  //         reslove.data.message.id
                  //       )
                  //       .then(() => {
                  //         tips();
                  //         form.resetFields();
                  //       });
                  //   }
                  // );

                  mailApi
                    .annexUpload(
                      res2.data.uploadUrl,
                      res.data.access_token,
                      file
                    )
                    .then(() => {
                      mailApi
                        .send(
                          "webmailer@ins-ic.com",
                          res.data.access_token,
                          reslove.data.message.id
                        )
                        .then(() => {
                          setLoadings(false);
                          tips();
                          form.resetFields();
                        });
                    });

                });
            }
          });
      });
  };

  // const uploadFile = (url, token) => {
  //   const map = file.map((item) => {
  //     return mailApi.annexUpload(url, token, item);
  //   });
  //   return Promise.all(map);
  // };

  const tips = () => {
    Modal.info({
      title: "提交成功",
      content: (
        <div>
          <p>您的申请已提交！</p>
          <p>我们将会在10个工作日内与您联系。</p>
        </div>
      ),
    });
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <>
      <div className={style.illustrations}>
        <img
          style={{ width: "100%", height: "100%" }}
          src="/img/agents_top.jpg"
          alt=""
        />
      </div>

      <div className={style.agents_title}>
        <div className={style.title}>代理商申请 </div>
        <div className={style.profile}>
          感谢您造访英思官方网站，若您想成为英思产品的代理商，请填写以下资料，我们将尽快与您联系！
        </div>
      </div>

      <div className={style.agents_content}>
        <Form
          name="basic"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={onSubmit}
          form={form}
        >
          <Form.Item
            label="代理商类型"
            name="agents"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "请选择代理商类型" }]}
          >
            {/* onChange={onChange} value={value} */}
            <Radio.Group>
              <Radio value={1}>芯片产品代理商</Radio>
              <Radio value={2}>教学产品代理商</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="您的姓名"
            name="username"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "请输入您的姓名" }]}
          >
            <Input placeholder="请输入您的姓名" />
          </Form.Item>

          <Form.Item
            label="您的职位"
            name="positions"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "请输入您的职位" }]}
          >
            <Input placeholder="请输入您的职位" />
          </Form.Item>

          <Form.Item
            label="公司/机构名称"
            name="institutionName"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "请输入公司/机构名称" }]}
          >
            <Input placeholder="请输入公司/机构名称" />
          </Form.Item>

          <Form.Item
            label="公司/机构主营业务"
            name="mainBusiness"
            labelCol={{ span: 24 }}
            // rules={[{ required: true, message: "请输入公司/机构主营业务" }]}
          >
            <Input placeholder="请输入公司/机构主营业务" />
          </Form.Item>

          <Form.Item
            label="公司/机构介绍"
            name="organization"
            labelCol={{ span: 24 }}
            // rules={[{ required: true, message: "请输入公司/机构主营业务" }]}
          >
            {/*  value={value}
              onChange={(e) => setValue(e.target.value)} */}
            <TextArea
              placeholder="请输入公司/机构介绍"
              autoSize={{ minRows: 8, maxRows: 10 }}
              style={{ marginBottom: "10px" }}
            />

            {/* style={{ display: "flex", alignItems: "center" }} */}
            <div className={style.upload_wrap} >
              <Upload {...props} maxCount={1}>
                <Button icon={<UploadOutlined />}>选择上传文件</Button>
              </Upload>
              <span className={style.upload_tips}>
                上传pdf、word、ppt文件，且不超过100M
              </span>
            </div>
          </Form.Item>

          <Form.Item
            label="您的邮箱"
            name="mailbox"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "请输入您的邮箱" }]}
          >
            <Input placeholder="请输入您的邮箱" />
          </Form.Item>

          <Form.Item
            label="您的手机号"
            name="phone"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "请输入您的手机号" }]}
          >
            <Input placeholder="请输入您的手机号" />
          </Form.Item>

          <Form.Item
            label="留言内容"
            name="message"
            labelCol={{ span: 24 }}
            // rules={[{ required: true, message: "请输入企业名称" }]}
          >
            <TextArea
              placeholder="请输入留言内容"
              autoSize={{ minRows: 8, maxRows: 10 }}
            />
          </Form.Item>

          {/*  {...tailLayout} */}
          <Form.Item>
            <Space className={style.space_wrap}>
              <Button
                htmlType="button"
                className={style.base_btn}
                onClick={onReset}
              >
                重置
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                className={style.base_btn}
                loading={loadings}
              >
                提交
              </Button>
            </Space>
          </Form.Item>
        </Form>

        <PrivacyAgreement></PrivacyAgreement>
      </div>
    </>
  );
}
