// 引入redux
import { legacy_createStore as createStore , combineReducers } from 'redux'
import { tokenReducer , useInfoReducer } from './modules/use/reducer'
import { navsReducer } from './modules/navList/reducer'

// 汇总
const reducerAll = combineReducers({
    use:tokenReducer,
    setUseInfo: useInfoReducer,
    navList: navsReducer, 
})

export default createStore(reducerAll);
