import React, { useRef, useState } from "react";
import style from "./Introduce.module.less";
import { Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

export default function ApplicationCase() {
  const carouselRef = useRef(null);

  const [carouselIndex, setCarouselIndex] = useState(0);

  const contentStyle: React.CSSProperties = {
    margin: 0,
    height: "412px",
    color: "#fff",
    lineHeight: "412px",
    textAlign: "center",
    display:'flex',
    justifyContent:'center'
    // background: "#364d79",
  };

  let arr = [
    {
      title: "普通高等院校案例",
      icon: "/img/case_1.png",
    },
    {
      title: "高等职业院校案例",
      icon: "/img/case_2.png",
    },
    {
      title: "企业案例",
      icon: "/img/case_3.png",
    },
  ];

  const changeCarouse = (index) => {
    const dom = carouselRef.current;
    dom.goTo(index);
    setCarouselIndex(index);
  };

  // 记录走马灯
  const recordCarousel = (index) => {
    setCarouselIndex(index);
  };

  const changeCarousel = (code) => {
    const dom = carouselRef.current;
    if (code === "left") {
      dom.prev();
    } else if (code === "right") {
      dom.next();
    }
  };

  return (
    <div className={style.application_case}>
      <div className={style.application_list}>
        {arr.map((item, index) => {
          return (
            <div
              key={index}
              className={[
                index == carouselIndex ? style.case_active : "",
                style.case_title,
              ].join(" ")}
              onClick={() => changeCarouse(index)}
            >
              {item.title}
            </div>
          );
        })}
      </div>

      <div className={style.carousel_wrap}>
        <div
          className={style.arrow_left}
          onClick={() => changeCarousel("left")}
        >
          <LeftOutlined />
        </div>
        <div
          className={style.arrow_right}
          onClick={() => changeCarousel("right")}
        >
          <RightOutlined />
        </div>

        <Carousel
          infinite={false}
          ref={carouselRef}
          afterChange={recordCarousel}
        >
          {arr.map((item) => {
            return (
              <div key={item.title}>
                <div style={contentStyle}>
                  <img
                    style={{ 
                        width: item.title == '企业案例' ? '48%' : '100%', 
                        height: "100%" 
                    }}
                    src={item.icon}
                    alt=""
                  />
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
}
