import React from "react";
import style from "./ChipTitle.module.less";

export default function ChipTitle(props) {
  return (
    <>
      <div className={style.chip_title}>
        <span className={style.title}>{ props.titleName }</span>
        <div className={style.line}></div>
      </div>
      <div className={style.chip_wrap}>{props.children}</div>
    </>
  );
}
