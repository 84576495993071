import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Modal, Table, Form, Input, Button } from "antd";
import style from "./ApplyDownload.module.less";
import PrivacyAgreement from "@/component/PrivacyAgreement/PrivacyAgreement";
import { Checkbox } from "antd";
import mailApi from "@/api/mail";

const ApplyDownload = forwardRef((props, ref) => {
  const { TextArea } = Input;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [form] = Form.useForm();

  let information = {};

  const handleCancelShow = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onSubmit = (values) => {
    let result = "";
    for (let key in values.information) {
      result += `<h3>${key}:</h3>`;
      values.information[key].forEach((item) => {
        result += `<p>${item}</p>`;
      });
    }

    let text = `
        <p>姓名 : ${values.userName}</p>
        <p>企业名称 : ${values.companyName}</p>
        <p>企业邮箱 : ${values.enterpriseMailbox}</p>
        <p>手机号码 : ${values.phone}</p>
    `;
    text += result;
    text += `<p>留言:${values.message}</p>`

    // 创建草稿
    mailApi
      .getMailToken({
        grant_type: "client_credentials",
        client_id: "cZof0XCwQMdFR4zO",
        client_secret:
          "ZzKCMhDSCar5AfFo7J5DUQkyKHNt4cT8amPChqDFBGjGI0parGCjyQAZjC3UHPAv",
      })
      .then((res) => {
        // 创建要发送的会话
        mailApi
          .getMailMessages(
            {
              message: {
                internetMessageId: "",
                subject: "芯片产品申请下载",
                summary: "摘要",
                priority: "PRY_HIGH",
                isReadReceiptRequested: false,
                from: {
                  email: "webmailer@ins-ic.com",
                  name: "webmailer@ins-ic.com",
                },
                toRecipients: [
                  {
                    email: "info@ins-ic.com",
                    name: "info@ins-ic.com",
                  },
                  // {
                  //   email: "yongpeng.lin@ins-ic.com",
                  //   name: "yongpeng.lin@ins-ic.com",
                  // },
                ],
                ccRecipients: [
                  {
                    email: "",
                    name: "",
                  },
                ],
                bccRecipients: [
                  {
                    email: "",
                    name: "",
                  },
                ],
                replyTo: [
                  {
                    email: "",
                    name: "",
                  },
                ],
                body: {
                  bodyText: "",
                  bodyHtml: text,
                },
                internetMessageHeaders: {
                  property1: "",
                  property2: "",
                },
                tags: [""],
              },
            },
            "webmailer@ins-ic.com",
            res.data.access_token
          )
          .then((reslove) => {
            setIsModalOpen(false);
            mailApi
              .send(
                "webmailer@ins-ic.com",
                res.data.access_token,
                reslove.data.message.id
              )
              .then(() => {
                form.resetFields();
                Modal.info({
                  title: "提交成功",
                  content: (
                    <div>
                      <p>您的申请已提交！</p>
                      <p>我们将会在10工作日内与您联系!</p>
                    </div>
                  ),
                });
              });
          });
      });
  };

  const onChange = (e, data, type) => {
    const obj = information[type];
    if (!obj && e.target.checked) {
      information[type] = [];
    }

    if (e.target.checked) {
      information[type].push(data);
    } else {
      information[type].splice(information[type].indexOf(data), 1);
      if (!information[type].length) {
        delete information[type];
      }
    }
    form.setFieldsValue({ information });
  };

  const columns = [
    {
      title: "用户手册",
      dataIndex: "manual",
      key: "manual",
      render: (data) => (
        <Checkbox onChange={(e) => onChange(e, data, "用户手册")}>
          {data}
        </Checkbox>
      ),
    },
    {
      title: "参考例程",
      dataIndex: "routine",
      key: "routine",
      render: (data) => (
        <>
          {data ? (
            <Checkbox onChange={(e) => onChange(e, data, "参考例程")}>
              {data}
            </Checkbox>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "开发工具",
      dataIndex: "tool",
      key: "tool",
      render: (data) => (
        <>
          {data ? (
            <Checkbox onChange={(e) => onChange(e, data, "开发工具")}>
              {data}
            </Checkbox>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "开发软件",
      dataIndex: "software",
      key: "software",
      render: (data) => (
        <Checkbox onChange={(e) => onChange(e, data, "开发软件")}>
          {data}
        </Checkbox>
      ),
    },
  ];

  const data = [
    {
      key: "44b37364-86ae-11ef-8b92-c5b565f5f046",
      manual: "YINS8S051x8x7用户手册",
      routine: "YINS8S051x8x7_sample",
      tool: "仿真器",
      software: "YINS_Touch_SW_V",
    },
    {
      key: "44b37365-86ae-11ef-8b92-c5b565f5f046",
      manual: "触摸调参软件使用说明",
      routine: "YINS8S051x_FWLib",
      tool: "脱机烧录器",
      software: "YINSDrvInstall",
    },
    {
      key: "44b37366-86ae-11ef-8b92-c5b565f5f046",
      manual: "YINS8开发平台使用说明",
      routine: "",
      tool: "",
      software: "Yins-C51Tool",
    },
  ];

  useImperativeHandle(ref, () => ({
    handleCancelShow,
  }));

  return (
    <Modal
      title="免费申请"
      width="900px"
      open={isModalOpen}
      destroyOnClose
      footer={null}
      onCancel={handleCancel}
      // onOk={handleSubm}
    >
      {/*        onFinish={onFinish}
          onFinishFailed={onFinishFailed} */}
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={onSubmit}
      >
        <div className={style.gather}>
          <Form.Item
            className={style.form_index}
            label="您的姓名"
            name="userName"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "请输入姓名" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className={style.form_index}
            label="您的企业名称"
            name="companyName"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "请输入企业名称" }]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className={style.gather}>
          <Form.Item
            className={style.form_index}
            label={
              <span>
                您的企业邮箱
                <span
                  style={{
                    fontSize: "12px",
                    color: "#d9001b",
                  }}
                >
                  （仅向企业开放，请勿填写个人邮箱）
                </span>
              </span>
            }
            name="enterpriseMailbox"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "请输入您的企业邮箱" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className={style.form_index}
            label="您的手机号码"
            name="phone"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "请输入手机号码" }]}
          >
            <Input />
          </Form.Item>
        </div>

        <Form.Item
          label="请勾选您需要下载的资料"
          labelCol={{ span: 24 }}
          name="information"
          //   rules={[{ required: true, message: "请勾选您需要下载的资料" }]}
        >
          <Table pagination={false} columns={columns} dataSource={data} />
        </Form.Item>

        <Form.Item label="留言" name="message" labelCol={{ span: 24 }}>
          <TextArea
            placeholder="输入留言"
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          {/* onClick={onSubmit} */}
          <Button type="primary" htmlType="submit">
            立即提交申请
          </Button>
        </Form.Item>
      </Form>
      <PrivacyAgreement></PrivacyAgreement>
    </Modal>
  );
});
export default ApplyDownload;
