import React from "react";
import style from "./ArticleTitle.module.less";
import { ClockCircleOutlined } from "@ant-design/icons";

export default function ArticleTitle(props) {

  return (
    <div className={style.article_title}>
      <div className={style.title}>
        { props.title }
      </div>

      <div className={style.released}>
        <ClockCircleOutlined />

        <span className={style.released_timer}>发布于 { props.time} </span>
        {/* <span >浏览量：{ props.views }</span> */}
      </div>
    </div>
  );
}
