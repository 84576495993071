export default {
    columns: [
        {
            title: "产品型号",
            width: 100,
            dataIndex: "model",
            key: "model",
            fixed: "left",
            draggable:false,
            
        },
        {
            title: "封装",
            width: 100,
            dataIndex: "encapsulation",
            key: "encapsulation",
        },
        {
            title: "GPIO",
            dataIndex: "GPIO",
            key: "GPIO",
        },
        {
            title: "CTK（KEY）",
            dataIndex: "CTK",
            key: "CTK",
        },
        {
            title: "WDT",
            dataIndex: "WDT",
            key: "WDT",
        },
        {
            title: "Timer0",
            dataIndex: "Timer0",
            key: "Timer0",
        },
        {
            title: "Timer1",
            dataIndex: "Timer1",
            key: "Timer1",
        },
        {
            title: "Timer2",
            dataIndex: "Timer2",
            key: "Timer2",
        },
        {
            title: "Timer3",
            dataIndex: "Timer3",
            key: "Timer3",
        },
        {
            title: "INT",
            dataIndex: "INT",
            key: "INT",
        },
        {
            title: "ADC",
            dataIndex: "ADC",
            key: "ADC",
        },

        {
            title: "OPA",
            dataIndex: "OPA",
            key: "OPA",
        },
        {
            title: "LED点阵",
            dataIndex: "LED",
            key: "LED",
        },
        {
            title: "LED矩阵",
            dataIndex: "LEDMatrix",
            key: "LEDMatrix",
        },
        {
            title: "LCD",
            dataIndex: "LCD",
            key: "LCD",
        },
        {
            title: "PWM",
            dataIndex: "PWM",
            key: "PWM",
        },
        {
            title: "I2C",
            dataIndex: "I2C",
            key: "I2C",
        },
        {
            title: "UART",
            dataIndex: "UART",
            key: "UART",
        },
        {
            title: "SPI",
            dataIndex: "SPI",
            key: "SPI",
        },
        
        {
            title: "工作频率（最高）",
            width: 100,
            dataIndex: "frequency",
            key: "frequency",
        },
        {
            title: "Flash",
            width: 100,
            dataIndex: "flash",
            key: "flash",
        },
        {
            title: "掉电复位",
            width: 100,
            dataIndex: "dump",
            key: "dump",
        },
        {
            title: "低电压检测",
            width: 120,
            dataIndex: "examine",
            key: "examine",
        },
        {
            title: "中断",
            width: 100,
            dataIndex: "suspend",
            key: "suspend",
        },
        {
            title: "供电电压(最小）",
            width: 100,
            dataIndex: "voltageMin",
            key: "voltageMin",
        },
        {
            title: "供电电压(最大）",
            width: 100,
            dataIndex: "voltageMax",
            key: "voltageMax",
        },
        {
            title: "工作温度（最低）",
            width: 100,
            dataIndex: "temperatureMin",
            key: "temperatureMin",
        },
        {
            title: "工作温度（最高）",
            width: 100,
            dataIndex: "temperatureMax",
            key: "temperatureMax",
        },




        // {
        //     title: "定时器数量",
        //     width: 110,
        //     dataIndex: "timer",
        //     key: "timer",
        // },
        // {
        //     title: "看门狗",
        //     width: 100,
        //     dataIndex: "watchdog",
        //     key: "watchdog",
        // },
        // {
        //     title: "I/O",
        //     width: 100,
        //     dataIndex: "io",
        //     key: "io",
        // },
    ],
    dataSource: [
        {
            key: 'f58be218-85e6-11ef-a273-033fd029655f',
            model: 'YINS8S051F8S7xxxx',
            encapsulation: 'SOP20',
            GPIO:'18',
            CTK:'18',
            WDT:'1',
            Timer0:'1',
            Timer1:'1',
            Timer2:'1',
            Timer3:'1',
            INT:'16',
            ADC:'18',
            OPA:'1',
            LED:'支持4*5、5*6、6*7、7*8模式',
            LEDMatrix:'4COM*8SEG',
            LCD:'6COM*8SEG',
            PWM:'4',
            I2C:'1',
            UART:'3',
            SPI:'1',
            frequency: '24MHz',
            flash: '64KByte',
            dump:'5档可配',
            examine:'8档可配',
            suspend:'支持',
            voltageMin:'2.5V',
            voltageMax :'5.5V',
            temperatureMin:'-40℃',
            temperatureMax:'105℃',
            // timer:'4',
            // watchdog:'1',
            // io:'18',
        },
        {
            key: 'f58be217-85e6-11ef-a273-033fd029655f',
            model: 'YINS8S051G8S7xxxx',
            encapsulation: 'SOP28',
            GPIO:'26',
            CTK:'26',
            WDT:'1',
            Timer0:'1',
            Timer1:'1',
            Timer2:'1',
            Timer3:'1',
            INT:'24',
            ADC:'26',
            OPA:'1',
            LED:'支持4*5、5*6、6*7、7*8模式',
            LEDMatrix:'4COM*8SEG',
            LCD:'8COM*14SEG',
            PWM:'4',
            I2C:'1',
            UART:'3',
            SPI:'1',
            frequency: '24MHz',
            flash: '64KByte',
            dump:'5档可配',
            examine:'8档可配',
            suspend:'支持',
            voltageMin:'2.5V',
            voltageMax :'5.5V',
            temperatureMin:'-40℃',
            temperatureMax:'105℃',
            // timer:'4',
            // watchdog:'1',
            // io:'26',
        },
        {
            key: 'f58be216-85e6-11ef-a273-033fd029655f',
            model: 'YINS8S051S8L7xxxx',
            encapsulation: 'LQFP44',
            GPIO:'42',
            CTK:'42',
            WDT:'1',
            Timer0:'1',
            Timer1:'1',
            Timer2:'1',
            Timer3:'1',
            INT:'38',
            ADC:'42',
            OPA:'1',
            LED:'支持4*5、5*6、6*7、7*8模式',
            LEDMatrix:'8COM*8SEG',
            LCD:'8COM*24SEG',
            PWM:'4',
            I2C:'1',
            UART:'3',
            SPI:'1',

            frequency: '24MHz',
            flash: '64KByte',
            dump:'5档可配',
            examine:'8档可配',
            suspend:'支持',
            voltageMin:'2.5V',
            voltageMax :'5.5V',
            temperatureMin:'-40℃',
            temperatureMax:'105℃',
            // timer:'4',
            // watchdog:'1',
            // io:'42',
        },
    ]
}