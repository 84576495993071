import React, { useEffect, useState } from "react";
import style from "./NextArticle.module.less";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import data from "@/static/json/news.js";

export default function NextArticle() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [previous, setPrevious] = useState({
    id: "",
    title: "",
  });
  const [next, setNext] = useState({
    id: "",
    title: "",
  });

  useEffect(() => {
    getArticle(state.id);
  }, []);

  const getFinedIndex = (id) => {
    return data.findIndex((item) => item.id == id);
  };

  const getArticle = (id) => {
    const index = getFinedIndex(id);
    if (data[index - 1]) {
      setPrevious({
        id: data[index - 1].id,
        title: data[index - 1].title,
      });
    } else {
      setPrevious({
        id: "",
        title: "",
      });
    }

    if (data[index + 1] && index !== data.length - 1) {
      setNext({
        id: data[index + 1].id,
        title: data[index + 1].title,
      });
    } else {
      setNext({
        id: "",
        title: "",
      });
    }
  };

  const pageChange = (id) => {
    getArticle(id);
    window.scrollTo(0, 0);
    navigate("/article", {
      state: {
        id,
      },
    });
  };

  return (
    <div className={style.next_Article}>
      {previous.title ? (
        <div className={style.previous} onClick={() => pageChange(previous.id)}>
          <LeftCircleOutlined style={{ marginRight: "10px" }} />
          {previous.title}
        </div>
      ) : (
        ""
      )}

      {next.title ? (
        <div
          className={[
            style.next,
            !previous.title ? style.next_border : "",
          ].join(" ")}
          onClick={() => pageChange(next.id)}
        >
          <RightCircleOutlined style={{ marginRight: "10px" }} />
          {next.title}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
