import {
    SET_NAVS,
    SET_ALL_NAVS,
} from '../../mutation'


const navListInit = [
    {
        functionname: '首页门户',
        fnid: "ff6dd7de38d74d2d9e71c9cd9c8e666a    ",
        uid: '1',
    }
]

export function navsReducer(preState = navListInit, action) {
    const { data, type } = action;
    if (!data) return preState;

    switch (type) {
        case SET_NAVS:
            // 长度验证
            if (preState.length > 7) {
                preState.splice(1, 1);
                return preState;
            }
            // 判断去重
            let judge = preState.some(item => item.fnid == data.fnid);
            if (!judge) preState.push(data);
            return preState;
        case SET_ALL_NAVS:
            preState.splice(data, 1);
            return preState;
        default:
            return preState;
    }
}