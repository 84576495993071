import React from "react";
import style from "./HotArticles.module.less";

import data from "@/static/json/news.js";
import { useLocation, useNavigate } from "react-router-dom";

export default function HotArticles() {
  const navigate = useNavigate();

  const {state} = useLocation();

  const myData = JSON.parse(JSON.stringify(data)).filter(item => item.id != state.id).splice(0, 3);

  const handleHot = (id) => {
    window.scrollTo(0,0)
    navigate("/article", {
      state: {
        id,
      },
    });
  };

  return (
    <div className={style.hot_articles}>
      <div className={style.hot_title}>热门新闻</div>
      <div className={style.hot_list_wrap}>
        {myData.map((item) => {
          return (
            <div className={style.hot_index} onClick={() => handleHot(item.id)}>
              <div className={style.hot_img}>
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={item.img}
                  alt=""
                />
              </div>
              <p className={style.hot_article_title}>{item.title}</p>
              <p className={style.hot_article}>{item.profile}</p>
            </div>
          );
        })}

        {/* <div className={style.hot_index}>
          <div className={style.hot_img}></div>
          <p className={style.hot_article_title}>
            算力平台：专为并发实时操作教学打造
          </p>
          <p className={style.hot_article}>
            算力平台是英思芯学专为集成电路设计教学而打造的软硬一体化产品
            算力平台是英思芯学专为集成电路设计教学而打造的软硬一体化产品
          </p>
        </div>

        <div className={style.hot_index}>
          <div className={style.hot_img}></div>
          <p className={style.hot_article_title}>
            算力平台：专为并发实时操作教学打造
          </p>
          <p className={style.hot_article}>
            算力平台是英思芯学专为集成电路设计教学而打造的软硬一体化产品
            算力平台是英思芯学专为集成电路设计教学而打造的软硬一体化产品
          </p>
        </div>

        <div className={style.hot_index}>
          <div className={style.hot_img}></div>
          <p className={style.hot_article_title}>
            算力平台：专为并发实时操作教学打造
          </p>
          <p className={style.hot_article}>
            算力平台是英思芯学专为集成电路设计教学而打造的软硬一体化产品
            算力平台是英思芯学专为集成电路设计教学而打造的软硬一体化产品
          </p>
        </div> */}
      </div>
    </div>
  );
}
