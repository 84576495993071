import route, {
  useRoutes,
  useLocation,
  useNavigate,
  Navigate,
  Routes,
  Route,
  matchRoutes,
} from "react-router-dom";
import router from "./routers/index";
// 这个hook 能够不引入connect 也能获取到redux
import { useSelector } from "react-redux";
import ScrollTop from "@/component/ScrollTop/ScrollTop";
import "./global.less";
import "@/static/fonts/font.css";

const RenderRouter = (router, token) => {
  return router.map((item) => {
    return (
      <Route key={item.path} path={item.path} element={item.element}>
        {item.children && RenderRouter(item.children, token)}
      </Route>
    );

    // 只拦截 需要授权 且 没有token 重定向回去首页
    // if (item.auth && !token)
    //   return <Route path="*" element={<Navigate to="/login" />} />;
    // return (
    //   <Route key={item.path} path={item.path} element={item.element}  >
    //     {item.children && RenderRouter(item.children, token)}
    //   </Route>
    // );
  });
};

function App() {
  const token = useSelector((state: any) => state.use);
  return (
    <ScrollTop>
      <Routes>{RenderRouter(router, token)}</Routes>
    </ScrollTop>
  );
}

export default App;
