/**
 * 将base64 转成file文件流
 * @param urlData   base64
 * @param fileName  文件名
 * @returns  file
 */
function dataURLtoBlob(urlData: string, fileName: string) {
    //将base64格式分割：['data:image/png;base64','XXXX']
    const arr = urlData.split(',');
    // .*？ 表示匹配任意字符到下一个符合条件的字符 刚好匹配到：
    // image/png
    let mime = arr[0].match(/:(.*?);/)[1];  //image/png

    //[image,png] 获取图片类型后缀
    const suffix = mime.split('/')[1] //png
    const bstr = atob(arr[1])   //atob() 方法用于解码使用 base-64 编码的字符串
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], `${fileName}.${suffix}`, {
        type: mime
    })
}

/**
 * 文件下载
 * @param url 文件地址
 * @param fileName 文件名称
 * @param type  文件类型
 */
const donwnload = async (url, fileName, type) => {
    if (type === 'exe') {
        const link = document.createElement('a');
        link.href = url;
        link.download = `${fileName}.exe`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        const response = await fetch(url);
        const exeBlob = await response.blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(exeBlob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
    }
}

export {
    dataURLtoBlob,
    donwnload,
}