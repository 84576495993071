import React from "react";
import style from "./Introduce.module.less";

export default function Construction(props) {
  return (
    <div className={style.construction_wrap}>
      <div className={style.construction_title_wrap}>
        <p className={style.title}>建设形态</p>
        <p className={style.profile}>
          英思芯学方案采用一站式TURN-KEY实验室建设方案，提供软硬件自主产品，轻松升级现有实验室。
        </p>
      </div>

      <div className={style.construction_illustration} >

        <img className={style.introduction_asemap} src="/img/introduction_asemap.jpg" alt="" />
        
        {/* 机房要求 */}
        <img className={
          [ style.machine_room , props.move ? style.machine_move :'' ].join(" ")
        } src="/img/machine_room.jpg" alt="" />

        {/* 面积要求 */}
        <img className={[style.area_icon , props.move ? style.area_move : '' ].join(" ")} src="/img/area_icon.jpg" alt="" />

        {/* 投影仪 */}
        <img className={[style.projector_icon , props.move ? style.projector_move : ''].join(" ")} src="/img/projector_icon.png" alt="" />

        {/* 实践系统 */}
        <img className={
          [ style.practice_icon , props.move ? style.projector_move : ''].join(" ")
        } src="/img/practice_icon.jpg" alt="" />

        {/* 配套硬件 */}
        <img className={[style.hardware_img , props.move ? style.hardware_move : '' ].join(" ")} src="/img/hardware_img.jpg" alt="" />

      </div>
    </div>
  );
}
