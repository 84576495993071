import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Modal, Button, Checkbox, Form, Input } from "antd";
import style from "./SampleCopy.module.less";
import mailApi from "@/api/mail";
import PrivacyAgreement from '@/component/PrivacyAgreement/PrivacyAgreement'


const SampleCopy = forwardRef((props, ref) => {
  const { TextArea } = Input;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [form] = Form.useForm();

  // const showModal = () => {
  //   setIsModalOpen(true);
  // };

  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancelShow = () => {
    setIsModalOpen(true);
  };


  const onSubmit = (values) => {

    let text = `
      <p>企业名称：${values.companyName}</p>
      <p>姓名：${values.userName}</p>
      <p>电话：${values.phone}</p>
      <p>企业邮箱：${values.enterpriseMailbox}</p>
      <p>企业邮箱：${values.enterpriseMailbox}</p>
      <p>邮寄地址： ${values.emailAddress}</p> 
    `;

    /**
     * companyName  您的企业名称
     * userName
     * enterpriseMailbox  您的企业邮箱
     * emailAddress 邮寄地址
     * phone
     */
    mailApi
      .getMailToken({
        grant_type: "client_credentials",
        client_id: "cZof0XCwQMdFR4zO",
        client_secret:
          "ZzKCMhDSCar5AfFo7J5DUQkyKHNt4cT8amPChqDFBGjGI0parGCjyQAZjC3UHPAv",
      })
      .then((res) => {
        mailApi
          .getMailMessages(
            {
              message: {
                internetMessageId: "",
                subject: "芯片产品",
                summary: "摘要",
                priority: "PRY_HIGH",
                isReadReceiptRequested: false,
                from: {
                  email: "webmailer@ins-ic.com",
                  name: "webmailer@ins-ic.com",
                },
                toRecipients: [
                  {
                    email: "info@ins-ic.com",
                    name: "info@ins-ic.com",
                  },
                  // {
                  //   email:'yongpeng.lin@ins-ic.com',
                  //   name:'yongpeng.lin@ins-ic.com',
                  // }
                ],
                ccRecipients: [
                  {
                    email: "",
                    name: "",
                  },
                ],
                bccRecipients: [
                  {
                    email: "",
                    name: "",
                  },
                ],
                replyTo: [
                  {
                    email: "",
                    name: "",
                  },
                ],
                body: {
                  bodyText: "",
                  bodyHtml: text,
                },
                internetMessageHeaders: {
                  property1: "",
                  property2: "",
                },
                tags: [""],
              },
            },
            "webmailer@ins-ic.com",
            res.data.access_token
          )
          .then((reslove) => {
            mailApi
              .send(
                "webmailer@ins-ic.com",
                res.data.access_token,
                reslove.data.message.id
              )
              .then(() => {
                setIsModalOpen(false);
                form.resetFields();
                Modal.info({
                  title: "提交成功",
                  content: (
                    <div>
                      <p>您的申请已提交！</p>
                      <p>我们将会在10个工作日内与您联系。</p>
                    </div>
                  ),
                });
              });
          });
      });
  };

  useImperativeHandle(ref, () => ({
    handleCancelShow,
  }));

  return (
    <Modal
      title="免费申请"
      width="800px"
      open={isModalOpen}
      destroyOnClose
      footer={null}
      onCancel={handleCancel}
      // onOk={handleSubm}
    >
      {/*        onFinish={onFinish}
        onFinishFailed={onFinishFailed} */}
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={onSubmit}
      >
        <div className={style.gather}>
          <Form.Item
            className={style.form_index}
            label="您的企业名称"
            name="companyName"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "请输入企业名称" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            className={style.form_index}
            label="您的姓名"
            name="userName"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "请输入姓名" }]}
          >
            <Input />
          </Form.Item>
        </div>

        <div className={style.gather}>
          <Form.Item
            className={style.form_index}
            label={
              <span>
                您的企业邮箱
                <span
                  style={{
                    fontSize: "12px",
                    color: "#d9001b",
                  }}
                >
                  （仅向企业开放，请勿填写个人邮箱）
                </span>
              </span>
            }
            name="enterpriseMailbox"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "请输入您的企业邮箱" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            className={style.form_index}
            label="邮寄地址"
            name="emailAddress"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "请输入邮寄地址" }]}
          >
            <Input />
          </Form.Item>
        </div>

        <div className={style.gather}>
          <Form.Item
            className={style.form_index}
            label="您的手机号码"
            name="phone"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "请输入手机号码" }]}
          >
            <Input />
          </Form.Item>
        </div>

        <Form.Item label="留言" name="message" labelCol={{ span: 24 }}>
          <TextArea
            placeholder="输入留言"
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          {/* onClick={onSubmit} */}
          <Button type="primary" htmlType="submit">
            立即提交申请
          </Button>
        </Form.Item>
      </Form>
      <PrivacyAgreement></PrivacyAgreement>

    </Modal>
  );
});

export default SampleCopy;
