import React from "react";

import style from "./AboutYins.module.less";

export default function AboutYins() {
  return (
    <>
      <div className={style.illustrations}>
        <img
          style={{ width: "100%", height: "100%" }}
          src="/img/aboutYins_top.jpg"
          alt=""
        />
      </div>

      <div className={style.about_content}>
        <div className={style.about_title}>我们的发展历程</div>
        <img
          style={{ width: "100%", height: "100%" }}
          src="/img/about_content.png"
          alt=""
        />
      </div>
    </>
  );
}
