import React from 'react'
import style from './Introduce.module.less'

export default function ProductIntroduction(props) {
  return (
    <div className={style.product_introduction}>

        <div className={style.product_text}>      
            <p>英思芯学方案立足集成电路设计产业各研发岗位的技能需求，融入主流企业的研发体系，</p>
            <p>并提炼企业工程师逾十年的研发经验，助力学校最后一公里教学。</p>
        </div>

        <div className={style.product_img}>
          <div className={style.product_left}>
            <img src="/img/introduce_product_left.jpg" style={{width:"100%",height:'100%'}} alt="" />
          </div>

          {/* props */}
          {/* className={style.product_right} */}
          <div 
            className={[style.product_right , props.move ? style.product_move :''  ].join(" ")}
          >
            <img src="/img/introduce_product_right.jpg" style={{width:"100%",height:'100%'}} alt="" />
          </div>
        </div>
    </div>
  )
}
