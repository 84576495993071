export default [
    {
        id:'9a94b956-7ed8-11ef-a273-033fd029655f',
        title: '英思集成携手广东省电子学会共建工程师专业水平评价新标准',
        time: '2024.07.26',
        views: '598',
        content: [
            {
                text: `
                    <p>2024年7月26日，广东省电子学会（以下简称电子学会）与珠海市英思集成电路设计有限公司（以下简称英思集成）于珠海市香洲区云溪谷A座英思集成珠海办公室签署《集成电路产业水平评价合作协议》，此次签约仪式标志着双方在集成电路产业工程师水平方面的深度合作，也标志着英思集成在集成电路产业联盟计划迈出重要的一步。</p>
                    <p>电子学会秘书长彭志聪、彭翔、戴凯琳、英思集成总经理郑观东、陈麟、李牡丹以及蔡银屏等人出席签约仪式。</p>
                `,
                img: "<img src='/img/article/1-1.jpg' style='width:550px;height:400px' />"
            },
            {
                text: `
                    <p>结合电子学会的标准建设资源以及英思集成的实践教学平台，双方共同实施“粤港澳大湾区电子信息工程技术人员水平评价和专业资格互认试点工作”，编制集成电路产教融合课程，旨在建立起集成电路水平评价标准，为院校培养、企业人才接收提供有效的标准体系，并推动该项目融入人社部门的标准体系。</p>
                    <p>签约仪式后，双方还就如何建立更符合当前产业需求的集成电路设计人才标准展开讨论，一致认同要以产业人才需求为导向，不断推进人才标准面向企业和行业需求迭代更新，以确保培养出的人才能够有效地满足行业的技术需求。此外，双方还探讨了如何推动产业联盟生态和产业学院的建设和发展。</p>
                `,
                img: "<img src='/img/article/1-2.jpg' style='width:680px;height:830px' />"
            },
            {
                title: '广东省电子学会介绍',
                text: `
                    <p>广东省电子学会是由广东省科学技术协会为主管，广东省民政厅登记,中国电子学会为指导的承担广东省电子信息行业科技类社会化公共服务职能的非营利性科技社团。主要业务为开展学术交流、科技服务、科学普及、专业技术人才培养等，经中国科协及省科协批准开展粤港澳大湾区电子信息工程技术人员水平评价和专业资格互认试点工作，并已与香港电子学会签署了工程师能力评价互认协议。</p>
                `,
            },
            {
                title: '英思介绍',
                text: `
                   <p>珠海市英思集成电路设计有限公司是一家致力于工业级微控制器的集成电路设计型企业，由陈大同先生、王洪魁先生、珠海一微半导体股份有限公司、广东省科学院珠海产业技术研究院以及多位跨行业经营者共同投资。公司的主营业务为隔空触控器的研发和销售，产品具备高集成度、高性价比、易开发的特点，帮助客户优化成本并满足多样化的需求。公司已建立“触控器+教学平台”双驱产品线，推出业界首款芯片全流程实践教学系统，满足高校最后一公里教学需求。未来，公司将挖掘芯片技术的商业价值，持续推出具有市场竞争力的产品，拓展更广泛的应用领域和客户覆盖范围，致力于成为家电领域具影响力的集成电路设计公司。</p>
                `,
            },
        ]
    },
    {
        id: '9a94b957-7ed8-11ef-a273-033fd029655f',
        title: '暨芯研究院—英思集成电路产学研创新基地正式运营！',
        time: '2024.05.27',
        views: '498',
        content: [
            {
                text: `
                    <p>2024年5月25日，暨芯研究院—英思集成电路产学研创新基地成立仪式在浙江省湖州市吴江区太湖实验室举行。</p>
                    <p>本次活动由暨芯集成电路产业研究院（以下简称暨芯研究院）、珠海市英思集成电路设计有限公司（以下简称英思集成）主办，暨南大学微电子学院、暨芯—华为现代产业学院协办。湖州市吴兴区科技局副局长莫慧忠、暨芯研究院执行院长刘向农、乌克兰核源金属研究院Yesypenko Alla、英思集成电路设计有限公司总经理郑观东、湖州学院电子信息学院党委书记黄旭、南京云开数据科技有限公司苏小亮、湖州积微电子科技有限公司代表沈女士，以及湖州学院、湖州师范学院、长兴技师学院、浙江信息工程学校、湖州工程技师学院、湖州职业技术学院的老师和同学参加活动</p>
                `,
                img: "<img src='/img/article/3-1.jpg' style='width:650px;height:430px' />"
            },
            {
                text: `
                    <p>湖州市吴兴区科技局副局长莫慧忠亲临现场，对创新基地的成立表达了祝贺。莫慧忠副局长详细介绍湖州市的集成电路产业发展战略，期待创新基地结合湖州市本地高校，在集成电路产业发展、人才培养以及产教融合促进等领域将发挥的重要作用，促进湖州市集成电路产业的发展，辐射浙江省乃至长三角区域。 </p>
                `,
                img: "<img src='/img/article/3-2.jpg' style='width:600px;height:400px' />",
                tips:'湖州市吴兴区科技局副局长莫慧忠',
            },
            {
                text: `
                    <p>暨芯研究院执行院长刘向农介绍了研究院的产业使命，分享了浙江省有关集成电路人才的相关政策。刘向农院长分享在ARM主导教育生态的经验以及从事芯片产业近20年经验，充分肯定英思集成的实践教学产品，补齐了研究院在集成电路设计板块的布局，为湖州市乃至浙江省输出更多契合产业需求的人才，助力产业高质量发展。 </p>
                `,
                img: "<img src='/img/article/3-3.jpg' style='width:600px;height:430px' />",
                tips:'暨芯研究院执行院长刘向农',
            },
            {
                text: `
                    <p>英思集成总经理郑观东介绍了英思集成的芯片产品以及产教融合生态的建设规划。郑观东分享英思集成在产教融合的技术资源和产业资源，阐明英思集成立足产业需求推动产教融合生态的产品观点，随着创新基地的成立，英思集成将全力建设长三角区域的产教融合生态，协同暨芯研究院为湖州市乃至浙江省创造差异化价值。</p>
                `,
                img: "<img src='/img/article/3-4.jpg' style='width:600px;height:430px' />",
                tips:'英思集成总经理郑观东',
            },
            {
                text: `
                    <p>暨芯研究院副院长黄新成介绍了创新基地的成立背景和发展目标。黄新成副院长详细分析集成电路设计产业的人力缺乏情况以及现有集成电路设计教学面临的困境，详细介绍创新基地依托英思集成的实践教学产品解决教学困境的举措，让零基础的老师和学生无压力开展集成电路设计教学。</p>
                `,
                img: "<img src='/img/article/3-5.jpg' style='width:580px;height:430px' />",
                tips:'暨芯研究院副院长黄新成',
            },
            {
                text: `
                    <p>英思集成长三角区域销售总监丁彦杰介绍了中国集成电路设计产业的发展情况以及人才需求。丁彦杰详细分享芯片的结构、晶圆与Die的关系以及各专业术语，详细介绍中国集成电路设计产业的发展规模、政府政策以及人力需求情况，并以企业岗位的技能要求进行讲解。</p>
                `,
                img: "<img src='/img/article/3-6.jpg' style='width:600px;height:430px' />",
                tips:'英思集成长三角区域销售总监丁彦杰',
            },
            {
                text: `
                    <p>在创新基地发布顺利完成后，英思资深工程师赵少峰为创新基地演示集成电路设计的教学模式，以《一天教你设计模拟版图》为实践内容向现场老师和学生进行讲述，与会老师和学生充分了解模拟版图设计的全流程。与会老师就如何开展好模拟版图设计教学进行多方面发问，赵少峰工程师以近20年研发经验进行详细解答，强调模拟版图设计的重点是经验方法学，而不是EDA工具，并以MOS管的模拟版图设计为例进行现场演示。与会学生表示内容侧重于实践操作，非常实用，而且搭配的讲义和实验手册内容详细，可独立开展学习。</p>
                `,
                img: "<img src='/img/article/3-7.jpg' style='width:600px;height:430px' />",
                tips:'英思资深工程师赵少峰'
            },
            {
                title: '英思实践平台',
                text: `
                   <p>英思实践平台由英思集成提炼企业研发标准和企业工程师经验，基于一颗英思商用芯片的技术资源，所打造的软硬一体化实践教学平台。该平台适合零基础实践，让学生在校期间即可完成一颗芯片的研发，覆盖芯片设计、芯片测试和芯片应用，助力集成电路设计教学接轨产业！</p>
                `,
            },
            {
                title: '暨芯集成电路产业研究院介绍',
                text: `
                   <p>暨芯集成电路产业研究院是由湖州市吴兴区政府和暨南大学微电子学院共建的新型科研教育机构。 研究院集合暨大、阿里达摩院、华为的人才及科研成果， 聚焦RISC V芯片设计及应用、开源系统开发、半导体人才培养等方面，推动产学研合作发展，打造省级新型研发教育机构。</p>
                `,
            },
            {
                title: '英思介绍',
                text: `
                   <p>珠海市英思集成电路设计有限公司是一家致力于工业级微控制器的集成电路设计型企业，由陈大同先生、王洪魁先生、珠海一微半导体股份有限公司、广东省科学院珠海产业技术研究院以及多位跨行业经营者共同投资。公司的主营业务为隔空触控器的研发和销售，产品具备高集成度、高性价比、易开发的特点，帮助客户优化成本并满足多样化的需求。公司已建立“触控器+教学平台”双驱产品线，推出业界首款芯片全流程实践教学平台，满足高校最后一公里教学需求。未来，公司将挖掘芯片技术的商业价值，持续推出具有市场竞争力的产品，拓展更广泛的应用领域和客户覆盖范围，致力于成为家电领域具影响力的集成电路设计公司。</p>
                `,
            },
        ]
    },
    {
        id: '9a94b958-7ed8-11ef-a273-033fd029655f',
        title:'英思芯学V2.0重磅来袭！',
        time: '2024.06.21',
        views: '986',
        content: [
            {
                text: `
                   <p>英思芯学结合客户过去一年的实践教学经验，于今天正式发布英思芯学实践系统的V2.0版本。新版本形成了“简历+教管+实践+考核+证书”的多维度体系，重新优化了用户操作界面，为用户提供更加人性化的体验。</p>
                `,
            },
            {
                title: '一、人才立体简历：让企业全方位掌握学生质量',
                text: `
                   <p>英思芯学V2.0构建人才立体简历库，使企业能够全方位掌握学生的质量，不再局限于传统的纸质简历。人才立体简历库不仅包含学生的基本信息，还涵盖了其学习记录、实践数据等详细信息。企业可以通过该系统了解到学生在不同课程中的学习表现、实际操作中的技能掌握情况等，从而更好地评估学生的综合能力。</p>
                `,
                img: "<img src='/img/article/2-2.jpg' style='width:670px;height:630px' />"
            },
            {
                title: '二、教学管理系统：智慧教学，成效可视化',
                subtitle: '● 方便老师管理、统计教学成果',
                list: [
                    '轻松管理课程内容',
                    '灵活安排教学计划',
                    '实时统计教学成果和工作量',
                    '详细的教学分析报告',
                ],
                text: `
                   <p>英思芯学V2.0适配不同的教学计划，从减轻老师工作量为出发点，更精准体现教学工作质量，更全面掌握学生的学习质量。</p>
                `,
                img: "<img src='/img/article/2-3.jpg' style='width:660px;height:530px' />"
            },
            {
                subtitle: '● 学生掌握学习进度、趣味性学习',
                text: `
                    <p>英思芯学V2.0通过技能习得的方式，增加学生的学习趣味性，让学生更全面掌握学习状况，提高学习质量。</p>
                    <p>通过这种互动式的技能习得方式，增加了趣味性，也提高了学生的学习效率。</p>
                `,
                list: [
                    '清晰的学习进度',
                    '成就勋章系统',
                    '智能学情分析报告',
                ],
                img: "<img src='/img/article/2-4.jpg' style='width:660px;height:600px' />"
            },
            {
                title: '三、实践操作界面：强化零基础实践的便捷性',
                text: `
                    <p>英思芯学V2.0强化了以实践操作为核心的产品理念，针对零基础用户的操作习惯进行了界面升级。用户可以在实践操作的同时查看实验手册、课件和讲义，实现了理论与实践的无缝结合，极大提高了操作便捷性和学习效率。</p>
                `,
                img: "<img src='/img/article/2-1.jpg' style='width:700px;height:580px' />"
            },
            {
                title: '四、智能考试系统：更便捷组卷',
                text: `
                    <p>英思芯学V2.0提供庞大的题库，并结合本地化需求，从减轻老师工作量为出发点，让学习质量的考核更具灵活性。</p>
                `,
                list: [
                    '庞大的基础题库，轻松考核',
                    '灵活自定义，快速组卷',
                    '自动判分，实时反馈结果',
                ],
                img: "<img src='/img/article/2-5.jpg' style='width:1000px;height:560px' />"
            },
            {
                title: '五、产业认证生态：企业培养同步，工程师证书',
                text: `
                    <p>英思芯学V2.0已被知名集成电路设计企业导入，并用于新员工的职业技能训练。借助同一套实践系统，可让高校的集成电路设计教学质量符合产业要求，让学生无缝链接企业项目生态。</p>
                `,
                img: "<img src='/img/article/2-6.jpg' style='width:700px;height:510px' />"
            },
            {
                title: '英思芯学V2.0将学习质量与第三方认证机构挂钩，可颁发工程师水平证书',
                img: "<img src='/img/article/2-7.jpg' style='width:700px;height:510px' />"
            }
        ]
    },
    {
        id:'51882590-7ca7-11ef-a273-033fd029655f',
        title: '暨南大学-英思集成电路产教融合实践教学基地正式开展教学',
        time: '2024.04.15',
        views: '596',
        content: [
            {
                text: `
                   <p>2024年4月15日上午，由暨南大学信息科学技术学院电子工程系与珠海市英思集成电路设计有限公司（以下简称英思）共同建设的集成电路产教融合实践教学基地（以下简称教学基地）正式开展集成电路设计教学，基地采用双师制教学，由英思资深工程师提供教学基地的第一堂课：一站式全芯实践平台的实践教学指引。</p>
                   <p>首先，由英思资深工程师陈麟为教学基地引入中国集成电路设计产业信息，为老师们和同学们分享中国集成电路产业的现状、集成电路设计的全流程以及就业环境。接着，基于教学基地所部署的一站式全芯实践平台，陈麟详细讲述一站式全芯实践平台的操作内容，包括各教学板块的内容、课程体系的内容、实验操作平台的使用方式以及各教学板块对应的研发岗位技能要求等。</p>
                   <p>其次，由英思资深工程师赵少峰为教学基地演示集成电路设计的教学模式，以《版图中“match/匹配”的基本匹配原则》为示范课程进行理论讲解和实践演示，详细讲述了讲义内容与课件知识点的关联、课件知识点的理解和实验手册的内容。基于实验手册，赵少峰手把手演示实验操作的开展，并详细讲解实验测试内容。</p>
                `,
                img: "<img src='/img/article/4-1.jpg' style='width:870px;height:430px' />"
            },
            {
                text: `
                   <p>经过上午2小时的现场教学指引，教学基地顺利完成第一堂课的教学任务。</p>
                   <p> <span style='color:#1777ff'>邓婉玲老师：</span>“行业人才的需求各式各样，通过第一堂课教学，大家更了解行业人才的需求，从行业人才需求作为出发点给大家授课，才能达到教学最佳效果。未来教学希望工程师多来学校指导，把行业真实案例跟学生分享，以解决项目技术为出发点，丰富教学内容。”</p>
                   <p><span style='color:#1777ff'>黄波老师：</span>“集成电路这门课程不好上，感谢英思公司提供教学内容，结合产业现实人才需求转化成日常教学。以前教学内容相对单一，陈工今天站在行业人才需求的维度上全流程带来教学内容，给予师生触动。”</p>
                   <p><span style='color:#1777ff'>单浩亮学生：</span>“陈工的讲述非常有逻辑性，能很好的了解设计、制造与测试全流程，更清晰了解将来就业岗位的技能要求和学习内容的关联度。后半截讲版图匹配也挺好的，能结合案例学到知识点。”</p>
                `,
                img: "<img src='/img/article/4-2.jpg' style='width:800px;height:430px' />"
            },
            {
                subtitle: '基地揭牌',
                text: `
                   <p>“暨南大学-英思集成电路产教融合实践教学基地”于2023年12月23日正式揭牌，由暨南大学信息科学技术学院电子工程系与珠海市英思集成电路设计有限公司共同建设，采用英思自主研发的一站式全芯实践平台开展集成电路设计教学，旨在解决学校到集成电路设计产业的最后一公里，为中国集成电路设计产业输送契合产业需求的工程型人才。</p>
                   <p> 会上，知名集成电路产业投资人王洪魁先生表示“实践教学基地是对传统的校企合作的升级，实现了人才内循环模式”。广东省科学院珠海产业技术研究院副院长蒋晓明博士表示“实践基地连接了学校、科研机构和行业企业，为行业提供了共同的平台”。珠海一微半导体股份有限公司研发总监赵伟兵先生表示“教学基地将企业实际的工作需求和流程传导至人才培养阶段，为企业提前培养出优秀的工程师人才，减少了企业用人的成本和负担”。</p>
                `,
            },
            {
                subtitle: '一站式全芯实践平台',
                text: `
                   <p>一站式全芯实践平台由英思提炼企业研发标准和企业工程师经验，基于一颗英思商用芯片的技术资源，所打造的软硬一体化实践教学平台。该平台适合零基础教学，让学生在校期间即可完成一颗芯片的研发，覆盖芯片设计、芯片测试和芯片应用，助力集成电路设计教学接轨产业！</p>
                `,
                img: "<img src='/img/article/4-3.webp' style='width:600px;height:300px' />"
            },
            {
                title: '英思介绍',
                text: `
                   <p>珠海市英思集成电路设计有限公司是一家专注于工业级微控制器的集成电路设计型企业，由陈大同先生、王洪魁先生、珠海一微半导体股份有限公司、广东省科学院珠海产业技术研究院以及多位跨行业经营者共同投资。公司现已推出隔空触控器和芯片实践教学平台，拥有多个先进工艺制程的上百个自主研发IP。</p>
                `,
            },
        ],
    },
    {
        id:'5188258f-7ca7-11ef-a273-033fd029655f',
        title: '让学校接轨产业：与广东工业大学实验教学部的实践教学交流',
        time: '2024.04.23',
        views: '286',
        content: [
            {
                img: "<img src='/img/article/5-1.jpg' style='width:700px;height:430px' />"
            },
            {
                text: `
                   <p>4月22日，珠海市英思集成电路设计有限公司（以下简称英思集成）参与了广东省科学院珠海产业技术研究院（以下简称珠海产研院）党支部与广东工业大学实验教学部（以下简称实验教学部）大学物理实验中心党支部的支部共建活动。实验教学部党总支书记陈伟民，珠海产研院副院长张红烨，英思集成总经理郑观东等参加联合党建活动。</p>
                   <p>上午，实验教学部党总支书记陈伟民一行参观了珠海产研院为入孵企业提供的智慧共享展厅，深入了解了珠海产研院已引进的部分项目和为入孵企业提供的全方位科技赋能服务等方面的情况，并着重听取了英思集成产教融合实践平台相关介绍。</p>
                   <p>本次活动以集成电路产教融合实践平台建设为契机，由英思集成总经理郑观东为双方分享集成电路产业的现状以及产业人才的需求，并为双方着重介绍一站式全芯实践教学平台的产教价值。</p>
                   <p>此次活动充分显示了各方对集成电路产业的高度关注和合作意愿。实验教学部党总支书记陈伟民表示，期待与珠海产研院党支部以集成电路产教融合实践平台建设为契机，积极探索深化技术育成孵化合作模式，持续推动相关产业创新发展。</p>
                `,
                img: "<img src='/img/article/5-2.jpg' style='width:700px;height:430px' />"
            },
            {
                title: '一站式全芯实践平台',
                text: `<p>一站式全芯实践平台由英思集成提炼企业研发标准和企业工程师经验，基于一颗英思商用芯片的技术资源，所打造的软硬一体化实践教学平台。该平台适合零基础教学，让学生在校期间即可完成一颗芯片的研发，覆盖芯片设计、芯片测试和芯片应用，助力集成电路设计教学接轨产业！</p>`,
                img: "<img src='/img/article/4-3.webp' style='width:600px;height:300px' />"
            },
            {
                title:'广东省科学院珠海产业技术研究院介绍',
                text:`
                    <p>广东省科学院珠海产业技术研究院（以下简称珠海产研院）是广东省科学院与珠海市人民政府合作共建的重大科技创新平台。</p>
                    <p>珠海产研院确定了以技术育成孵化为主体，以创业科技企业集群和产业技术服务平台建设为两翼的“一体两翼”发展战略，依托广东省科学院一流科研机构的优势，建立市场化的现代新型研发机构治理体制和运营管理机制，围绕生物健康、智能制造、新能源、新材料等领域，不断探索前沿领域，瞄准先进技术引进消化吸收再创新，通过引进研发与投资孵化并举、科技金融与产业创新融合，促进政策链、创新链、产业链和资本链的深度融合，打通技术从创新、育成、孵化和产业化的通道，建立高效的产业技术创新和育成生态体系，在促进产业创新发展同时，实现技术成果的增值。</p>
                    <p>珠海产研院致力于打造成为集应用技术和产品研发、高端孵化器、风险投资、科技服务等功能为一体，市场化的新型研发机构，建设成为服务科技创新发展的综合性产业技术创新中心。</p>
                `
            },
            {
                title:'英思介绍',
                text:`
                    <p>珠海市英思集成电路设计有限公司是一家专注于工业级微控制器的集成电路设计型企业，由陈大同先生、王洪魁先生、珠海一微半导体股份有限公司、广东省科学院珠海产业技术研究院以及多位跨行业经营者共同投资。公司现已推出隔空触控器和芯片实践教学平台，拥有多个先进工艺制程的上百个自主研发IP。</p>
                `
            }
        ]
    }
]