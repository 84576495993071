// 变量


// 登录写入token
export const SET_TOKEN = 'Set_Token'
// 录入用户信息
export const SET_USE_INFO = 'Set_Use_Info'

// 点击录入导航栏经过的路径
export const SET_NAVS = 'Set_Navs'
// 存入经过筛查的所有导航路径
export const SET_ALL_NAVS ='Set_All_Navs'
// 添加侧边栏点击进行存储
export const  SET_SELECT = 'Set_Select' 