import React, { useEffect, useRef, useState } from "react";
import { Anchor } from "antd";
import style from "./ChipProducts.module.less";
import ChipTitle from "@/component/ChipTitle/ChipTitle";
import ChipTable from "@/component/ChipTable/ChipTable";
import FileTable from "@/component/FileTable/FileTable";
import chipData from "./chip_manual.js";
import manualData from "./manual.js";
import toolData from "./tool.js";
import developmentTools from "./developmentTools.js";
import SampleCopy from "@/component/SampleCopy/SampleCopy";
import ApplyDownload from "@/component/ApplyDownload/ApplyDownload";
import axios from 'axios';
// import { donwnload } from "@/utils/tool";

export default function ChipProducts() {
  const sampleRef = useRef(null);
  const ApplyDownloadRef = useRef(null);
  const [anchor, setAnchor] = useState("#part-1");

  // const onChange = (link: string) => {
  //   // setAnchor(link);
  // };

  const handle = (data) => {
    ApplyDownloadRef.current.handleCancelShow();

    /**
     * 
     * 旧代码 文件下载(封存)
     * 
      let file = null;
      let type = "";
      switch (data.fileName) {
        case "YINS8S051x8x7用户手册":
          file = "/file/英思集成网站服务协议.pdf";
          break;
        case "触摸调参软件使用说明":
          file = "/file/触摸调参软件使用说明.pdf";
          break;
        case "YINS8开发平台使用说明":
          file = "/file/YINS8开发平台使用说明.pdf";
          break;
        case "YINS8S051x8x7_sample":
          file = "/file/YINS8S051x8x7_sample.zip";
          break;
        case "YINS8S051x_FWLib":
          file = "/file/YINS8S051x_FWLib.zip";
          break;
        case "YINS_Touch_SW_V":
          file = "/file/YINS_Touch_SW_V1.05(触控调试工具).exe";
          type = "exe";
          break;
        case "YINSDrvInstall":
          file = "/file/YINSDrvInstall(仿真插件).exe";
          type = "exe";
          break;
        case "Yins-C51Tool":
          file = "/file/Yins-C51Tool.exe";
          type = "exe";
          break;
      }
      donwnload(file, data.fileName, type);
     * 
    */
  };

  const columns = [
    {
      title: "文件名称",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "版本",
      width: 100,
      dataIndex: "version",
      key: "version",
    },
    {
      title: "描述",
      dataIndex: "describe",
      key: "describe",
    },
    {
      title: "操作",
      dataIndex: "",
      key: "x",
      render: (data) => <a onClick={() => handle(data)}>下载</a>,
    },
  ];

  const columns2 = [
    {
      title: "产品",
      key: "products",
      width: 260,
      render: (_, { products }) => (
        <div
          style={{
            width: "100px",
            height: "95px",
            border: "1px solid #dddddd",
          }}
        >
          <img src={products} style={{ width: "100%", height: "100%" }} />
        </div>
      ),
    },
    {
      title: "文件名称",
      dataIndex: "fileName",
      key: "fileName",
      width: 300,
      render: (text) => <div className={style.file_table_index}>{text}</div>,
    },
    {
      title: "版本",
      width: 100,
      dataIndex: "version",
      key: "version",
      render: (text) => <div className={style.file_table_index}>{text}</div>,
    },
    {
      title: "描述",
      dataIndex: "describe",
      key: "describe",
      render: (text) => <div className={style.file_table_index}>{text}</div>,
    },
    {
      title: "操作",
      dataIndex: "",
      key: "x",
      width: 120,
      // render: (data) => <a onClick={() => handle(data)}>下载</a>,
      render: (data) => (
        <div className={style.file_table_index}>
          <a onClick={() => handle(data)}>下载</a>
        </div>
      ),
    },
  ];

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      var scrollTop = window.scrollY || document.documentElement.scrollTop;
      if (scrollTop < 1400) {
        setAnchor("#part-1");
      }
      if (scrollTop > 1400) {
        setAnchor("#part-2");
      }
      if (scrollTop > 2000) {
        setAnchor("#part-3");
      }
      if (scrollTop > 2900) {
        setAnchor("#part-4");
      }
      console.log(scrollTop);
    });
  }, []);

  const handleTryOut = () => {
    sampleRef.current.handleCancelShow();
  };

  const handleChangeAnuchor = (data) => {
    console.log(data);
    // data.target.innerText;
    const text = data.target.innerText;

    if (text === "产品概述") {
      window.scrollTo({
        top: 700,
        behavior: "smooth", 
      });
    }

    if (text === "芯片系列") {
      window.scrollTo({
        top: 1410,
        behavior: "smooth", 
      });
    }

    if (text === "技术文档") {
      window.scrollTo({
        top: 2047,
        behavior: "smooth", 
      });
    }

    if (text === "设计与开发") {
      window.scrollTo({
        top: 2928,
        behavior: "smooth", 
      });
    }
  };

  return (
    <>
      <div className={style.illustration}>
        <div className={style.free} onClick={handleTryOut}>
          获取样片
        </div>
      </div>
      <div className={style.chip_products}>
        <div className={style.navigate_wrap}>
          <div className={style.nav_background}>
            <img
              src="/img/nav_chip.png"
              style={{ width: "100%", height: "100%" }}
              alt=""
            />
          </div>

          <Anchor
            getCurrentAnchor={() => anchor}
            onClick={(data) => handleChangeAnuchor(data)}
            // onChange={onChange}
            items={[
              {
                key: "part-1",
                href: "#part-1",
                title: "产品概述",
              },
              {
                key: "part-2",
                href: "#part-2",
                title: "芯片系列",
              },
              {
                key: "part-3",
                href: "#part-3",
                title: "技术文档",
              },
              {
                key: "part-4",
                href: "#part-4",
                title: "设计与开发",
              },
            ]}
          />
        </div>

        <div className={style.chip_products_inner}>
          {/* id="part-1" */}
          <div className={style.chip_gather}>
            <ChipTitle titleName="产品概述">
              <span className={style.chip_text}>
                本产品是专为人体触摸设计的超强抗干扰、高防水性能、工规级质量标准人体静电感应触控芯片，广泛应用于基于微晶玻璃板、亚克力板等面板的人体触摸操控。产品集成的电容检测通道，可以用来检测近距离感应或者触摸，实现按键、滚轮、滑条、近场感应等多种应用。同时，产品集成热电偶测温通道，可以实现低温漂、宽温度范围的温度检测。
              </span>
            </ChipTitle>

            {/* <ChipTitle titleName="主要应用">
              <ul>
                <li className={[style.chip_text, style.point].join(" ")}>
                  家电交互面板、遥控器
                </li>
                <li className={[style.chip_text, style.point].join(" ")}>
                  开关交互面板
                </li>
                <li className={[style.chip_text, style.point].join(" ")}>
                  机器人交互面板、遥控器
                </li>
                <li className={[style.chip_text, style.point].join(" ")}>
                  移动设备交互面板
                </li>
              </ul>
            </ChipTitle> */}

            {/* <ChipTitle titleName="适用场景">
              <ul className={style.chip_ul}>
                <li
                  className={[
                    style.chip_text,
                    style.point,
                    style.chip_index,
                  ].join(" ")}
                >
                  触摸感应
                </li>
                <li
                  className={[
                    style.chip_text,
                    style.point,
                    style.chip_index,
                  ].join(" ")}
                >
                  温度测量
                </li>
                <li
                  className={[
                    style.chip_text,
                    style.point,
                    style.chip_index,
                  ].join(" ")}
                >
                  LED/LCD显示
                </li>
                <li
                  className={[
                    style.chip_text,
                    style.point,
                    style.chip_index,
                  ].join(" ")}
                >
                  电机控制
                </li>
                <li
                  className={[
                    style.chip_text,
                    style.point,
                    style.chip_index,
                  ].join(" ")}
                >
                  程序防篡改
                </li>
              </ul>
            </ChipTitle> */}

            <div
              style={{
                fontWeight: "normal",
                fontSize: "22px",
                color: "#919191",
                marginBottom:'30px'
              }}
            >
              适用场景
            </div>

            <ul className={style.chip_ul}>
              <li
                className={[
                  style.chip_text,
                  style.point,
                  style.chip_index,
                ].join(" ")}
              >
                触摸感应
              </li>
              <li
                className={[
                  style.chip_text,
                  style.point,
                  style.chip_index,
                ].join(" ")}
              >
                温度测量
              </li>
              <li
                className={[
                  style.chip_text,
                  style.point,
                  style.chip_index,
                ].join(" ")}
              >
                LED/LCD显示
              </li>
              <li
                className={[
                  style.chip_text,
                  style.point,
                  style.chip_index,
                ].join(" ")}
              >
                电机控制
              </li>
              <li
                className={[
                  style.chip_text,
                  style.point,
                  style.chip_index,
                ].join(" ")}
              >
                程序防篡改
              </li>
            </ul>
          </div>

          {/* id="part-2" */}
          <div className={style.chip_gather}>
            <ChipTitle titleName="芯片系列">
              <ChipTable></ChipTable>
            </ChipTitle>
          </div>

          {/* id="part-3" */}
          <div className={style.chip_gather}>
            <ChipTitle titleName="技术文档">
              {/* id="part-4" */}
              <div style={{ marginBottom: "71px" }}>
                <FileTable
                  title="用户手册"
                  dataSource={chipData.dataSource}
                  columns={columns}
                ></FileTable>
              </div>
              <FileTable
                title="参考例程"
                dataSource={manualData.dataSource}
                columns={columns}
              ></FileTable>
            </ChipTitle>
          </div>
          <div></div>

          <div className={style.chip_gather}>
            <ChipTitle titleName="设计与开发">
              <div style={{ marginBottom: "71px" }}>
                <FileTable
                  title="开发工具"
                  dataSource={developmentTools.dataSource}
                  columns={columns2}
                ></FileTable>
              </div>
              <FileTable
                title="开发软件"
                dataSource={toolData.dataSource}
                columns={columns2}
              ></FileTable>
            </ChipTitle>
          </div>
        </div>
      </div>
      {/* 样片 */}
      <SampleCopy ref={sampleRef}></SampleCopy>

      {/* 申请下载  */}
      <ApplyDownload ref={ApplyDownloadRef}></ApplyDownload>
    </>
  );
}
