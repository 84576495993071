import React, { useEffect } from "react";
import style from "./IndexPage.module.less";

export default function Consult() {
  const win = window as any;
  const AMap = win.AMap;

  useEffect(() => {
    const map = new AMap.Map("container", {
      viewMode: "3D",
      // zoom: 90, // 初始化地图级别
      zoom: 16, // 初始化地图级别
      resizeEnable: true,
      center: [113.513328, 22.281758], // 初始化地图中心点位置
      position: {
        longitude: 113.513328,
        latitude: 22.281758,
      },
      cursor: "pointer",
    });

    const infoWindow = new AMap.InfoWindow({
      content: `
        <div style='background:#fff;padding:10px;'>
            广东省珠海市香洲区旅游路168号云溪谷数字产业园A栋1702
        </div>
      `,
      offset: new AMap.Pixel(0, -30)
    });

    // 打开 InfoWindow
    infoWindow.open(map, new AMap.LngLat(113.513328, 22.281758));
  }, []);

  return (
    <div className={style.consult_wrap}>
      <div className={style.consult_left}>
        <div className={style.consult_left_box}>
          <div className={style.consult_left_title}>芯片业务</div>
          <div className={style.consult_left_text}>
            <p>电话：0756-2228982</p>
            <p>邮箱：icsales@ins-ic.com</p>
          </div>
        </div>
        <div className={style.consult_left_box}>
          <div className={style.consult_left_title}>教学业务</div>
          <div className={style.consult_left_text}>
            <p>电话：0756-2228982</p>
            <p>邮箱：edusales@ins-ic.com</p>
          </div>
        </div>
        <div className={style.consult_left_box}>
          <div className={style.consult_left_title}>关注教学产品</div>
          <div className={style.consult_code}>
            <div className={style.code}>
              <img
                style={{ width: "100%", height: "100%" }}
                src="/img/index_code.png"
                alt=""
              />
            </div>
            <div className={style.code_text}>英思芯学</div>
          </div>
        </div>
      </div>
      <div className={style.consult_right}>
        <div className={style.consult_right_title}>珠海总部</div>
        <div className={style.consult_right_address}>
          地址：广东省珠海市香洲区旅游路168号云溪谷数字产业园A栋1702
        </div>
        <div className={style.address}>
          <div id="container" style={{ width: "100%", height: "100%" }}></div>
          {/* <img
            style={{ width: "100%", height: "100%" }}
            src="/img/index_address.png"
            alt=""
          /> */}
        </div>
      </div>
    </div>
  );
}
