import React, { useState } from "react";
import style from "./Titlebox.module.less";
import { CSSTransition, SwitchTransition } from "react-transition-group";
export default function Titlebox(props) {

  return (
    <div
      className={style.title_box}
      style={{
        background: props.customBackground ? props.customBackground : "#fff",
      }}
    >
      <div className={style.title}>
        <div className={style.title_inner}>{props.titleName || ""}</div>
      </div>
      {props.children}
    </div>
  );
}
