import React, { useEffect, useRef, useState } from "react";

import Titlebox from "@/component/Titlebox/Titlebox";
import ProductIntroduction from "./ProductIntroduction";
import Construction from "./Construction";
import PracticeIntroduction from "./PracticeIntroduction";
import ComputingPlatform from "./ComputingPlatform";
import ApplicationCase from "./ApplicationCase";
import TryOutForm from "@/component/TryOutForm/TryOutForm";

import style from "./Introduce.module.less";

export default function Introduce() {
  const tryOutRef = useRef(null);

  const [introduceMove, setIntroduceMove] = useState({
    productsMove: false,
    roduceMove:false,
  });

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
  });

  const onScroll = (e) => {
    const scrollTop = document.documentElement.scrollTop;
    if (scrollTop < 100) {
      setIntroduceMove({
        productsMove: false,
        roduceMove:false,
      });
    }

    if (scrollTop > 500) {
      setIntroduceMove({
        productsMove: true,
        roduceMove:false,
      });
    }

    if(scrollTop > 1500){
      setIntroduceMove({
        productsMove: true,
        roduceMove:true,
      });
    }


  };

  const handleTryOut = () => {
    tryOutRef.current.handleTryOutShow();
  };

  return (
    <>
      <div className={style.introduce}>
        <div className={style.illustrations}>
          <img
            src="/img/introduce_top.png"
            style={{ width: "100%", height: "100%" }}
            alt=""
          />

          <div className={style.illustrations_text}>
            <p>让中国没有难教的</p>
            <p>集成电路教学</p>
          </div>

          <div className={style.illustrations_btn}>
            <div className={style.free} onClick={handleTryOut}>
              免费试用
            </div>
            {/* <div className={style.video_btn}>观看视频</div> */}
          </div>
        </div>

        <Titlebox titleName="产品概要">
          <ProductIntroduction move={introduceMove.productsMove} />
        </Titlebox>

        {/* <Titlebox titleName="建设形态" customBackground="#F8FAFF">
            <Construction />
        </Titlebox> */}
        <Construction move={introduceMove.roduceMove} />

        <PracticeIntroduction />

        <ComputingPlatform />

        <Titlebox titleName="应用案例">
          <ApplicationCase />
        </Titlebox>
      </div>
      <TryOutForm ref={tryOutRef}></TryOutForm>
    </>
  );
}
