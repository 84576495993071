import React, { useRef } from "react";
import { Button } from "antd";
import style from "./IndexPage.module.less";
import SampleCopy from "@/component/SampleCopy/SampleCopy";
import TryOutForm from "@/component/TryOutForm/TryOutForm";
import { useNavigate } from "react-router-dom";

export default function Productrecommendation(props) {
  // 样片
  const sampleRef = useRef(null);
  // 试用
  const tryOutRef = useRef(null);

  const navigate = useNavigate();

  const getSample = () => {
    // console.log(sampleRef.current.handleCancelShow);
    sampleRef.current.handleCancelShow();
  };

  const handleTryOut = () => {
    tryOutRef.current.handleTryOutShow();
  };

  const productIntroduction = () => {
    navigate('/introduce');
  }

  const handleChip = () => {
    navigate('/chipProducts');
  }

  return (
    <>
      <div className={style.product_recommendation}>
        <div className={style.series_1}>
          <div className={style.series_1_box}></div>
          <div className={style.series_1_wrap}>
            <div className={style.series_img}>
              <div
                className={[
                  style.series_img_position,
                  props.show.leftImg ? style.series_img_mobile : "",
                ].join(" ")}
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src="/img/index_sample_copy.jpg"
                  alt=""
                />
              </div>
            </div>

            <div
              className={style.series_content_wrap}
              style={{
                marginLeft: "60px",
              }}
            >
              <div className={style.series_content_inner}>
                <div onClick={handleChip}>
                  <div className={[style.series_h1 , style.series_hover].join(" ")}>YINS8S051x8x7系列</div>
                  <div className={[style.series_h2 , style.series_hover].join(" ")}>
                    内嵌Flash存储器的8位人体静电感应触控专用型芯片
                  </div>
                </div>
                <div className={style.series_describe}>
                  <p>4mm厚度+5mm隔空距离的无弹簧人体静电感应</p>
                  <p>低温漂热电偶全范围测温</p>
                  <p>Flash全区域安全保护</p>
                  <p>LCD/LED驱动显示</p>
                </div>


                <div className={style.base_btn}></div>
                <Button className={style.base_btn} onClick={getSample}>
                  获取样片
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={style.series_2}>
          <div className={style.series_2_box}></div>
          <div className={style.series_2_wrap}>
            <div
              className={style.series_content_wrap}
              style={{
                marginRight: "60px",
              }}
            >
              <div className={style.series_content_inner}>
                <div onClick={productIntroduction}>
                  <div className={[style.series_h1 , style.series_hover].join(' ')}>英思芯学实践一体机</div>
                  <div className={[style.series_h2 , style.series_hover].join(' ')}>
                    基于55nm CMOS工艺技术的集成电路设计技能实践教学平台
                  </div>
                </div>
                <div className={style.series_describe}>
                  <p>研发工程师逾十年研发经验提炼</p>
                  <p>适合零基础教学的多维度课程体系</p>
                  <p>能够自主完成一颗真实芯片的研发实践</p>
                </div>
                <Button className={style.base_btn} onClick={handleTryOut}>
                  免费试用
                </Button>
              </div>
            </div>
            <div className={style.series_2_img}>
              <div
                className={[
                  style.series_2_img_inner,
                  props.show.rightImg ? style.series2_img_moblie : "",
                ].join(" ")}
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src="/img/index_one_pc.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 样片 */}
      <SampleCopy ref={sampleRef}></SampleCopy>
      {/* 试用 */}
      <TryOutForm ref={tryOutRef}></TryOutForm>
    </>
  );
}
