import React from "react";
import style from "./Introduce.module.less";
import { Popover } from "antd";

export default function ComputingPlatform() {
  return (
    <div className={style.Computing_platform}>
      <div className={style.Computing_title}>英思芯学算力平台</div>
      <div className={style.Computing_tips}>
        专为英思实践系统的并发实时操作教学打造
      </div>

      <div className={style.Computing_wrap}>
        <div className={style.computing_body}>
          <img
            style={{ width: "100%", height: "100%" }}
            src="/img/computing_body.png"
            alt=""
          />
          <Popover
            placement="left"
            content={
              <div
                style={{
                  fontWeight: "normal",
                  fontSize: "18px",
                  color: "#1986FF",
                }}
              >
                <p>专用数据接口</p>
                <p>上传学校数据平台</p>
              </div>
            }
          >
            <div className={style.easy_upload}>
              <div className={style.easy_upload_inner}>易上传</div>
            </div>
          </Popover>
          <Popover
            placement="leftBottom"
            content={
              <div
                style={{
                  fontWeight: "normal",
                  fontSize: "18px",
                  color: "#1986FF",
                }}
              >
                <p>积木式扩展算力和存储</p>
                <p>灵活适配教学规模变化</p>
              </div>
            }
          >
            <div className={style.expand}>
              <div className={style.expand_inner}>易拓展</div>
            </div>
          </Popover>
          <Popover
            placement="leftBottom"
            content={
              <div
                style={{
                  fontWeight: "normal",
                  fontSize: "18px",
                  color: "#1986FF",
                }}
              >
                <p>10G光纤网络</p>
                <p>40人+并发实时操作</p>
              </div>
            }
          >
            <div className={style.concurrent}>
              <div className={style.concurrent_inner}>高并发</div>
            </div>
          </Popover>
          <Popover
            placement="leftTop"
            content={
              <div
                style={{
                  fontWeight: "normal",
                  fontSize: "18px",
                  color: "#1986FF",
                }}
              >
                <p>2ms延时</p>
                <p>图形化无感交互操作</p>
              </div>
            }
          >
            <div className={style.low_latency}>
              <div className={style.low_latency_inner}>低延时</div>
            </div>
          </Popover>
          <Popover
            placement="rightBottom"
            content={
              <div
                style={{
                  fontWeight: "normal",
                  fontSize: "18px",
                  color: "#1986FF",
                }}
              >
                <p>企业级数据保护</p>
                <p>数据本地化存储</p>
              </div>
            }
          >
            <div className={style.security}>
              <div className={style.security_inner}>高安全</div>
            </div>
          </Popover>

          <Popover
            placement="right"
            content={
              <div
                style={{
                  fontWeight: "normal",
                  fontSize: "18px",
                  color: "#1986FF",
                }}
              >
                <p>4平方米面积</p>
                <p>常规电气要求</p>
              </div>
            }
          >
            <div className={style.furnishings}>
              <div className={style.furnishings_inner}>易摆设</div>
            </div>
          </Popover>

          <Popover
            placement="rightTop"
            content={
              <div
                style={{
                  fontWeight: "normal",
                  fontSize: "18px",
                  color: "#1986FF",
                }}
              >
                <p>兼容各类EDA工具和处理器IP</p>
                <p>灵活匹配信创要求</p>
              </div>
            }
          >
            <div className={style.compatible}>
              <div className={style.compatible_inner}>高兼容</div>
            </div>
          </Popover>

          <Popover
            placement="left"
            content={
              <div
                style={{
                  fontWeight: "normal",
                  fontSize: "18px",
                  color: "#1986FF",
                }}
              >
                <p>13.9 TFLOPS</p>
                <p>快速运算实践数据</p>
              </div>
            }
          >
            <div className={style.high_power}>
              <div className={style.high_power_inner}>高算力</div>
            </div>
          </Popover>
        </div>
        
        <img
          src="/img/data_center.png"
          style={{ width: "100%", height: "100%" }}
          alt=""
        />
      </div>
    </div>
  );
}
