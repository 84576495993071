import React, { useEffect, useState } from "react";
import { DoubleRightOutlined } from "@ant-design/icons";
import style from "./IndexPage.module.less";
import data from "@/static/json/news.js";
import { useNavigate } from "react-router-dom";

export default function News() {
  const navigate = useNavigate();

  const [current, setCurrent] = useState(0);
  const [dataList, setData] = useState([]);
  const [showData, setShowData] = useState(data[0]);

  const [timer, setTimer] = useState(null);

  useEffect(() => {
    const currentData = JSON.parse(JSON.stringify(data)).splice(0, 4);
    setData(() => currentData);
    timing(currentData);
  }, []);

  const timing = (currentData) => {
    if(timer){
      clearInterval(timer);
    }

    const time = setInterval(() => {
      setCurrent((prevCount) => {
        let num = prevCount + 1;
        if (num == 4) num = 0;
        setShowData(currentData[num]);
        return num;
      });
    }, 7000);

    setTimer(time);

    return () => {
      clearInterval(timer);
    };
  };

  const handleMouse = (index) => {
    setCurrent(index);
    setShowData(dataList[index]);
  };

  const handleMore = () => {
    navigate("/news");
  };

  // 鼠标移入
  const handleMouseEnter = () => {
    clearInterval(timer);
  };

  // 鼠标移出
  const handleMouseLeave = () => {
    timing(dataList);
  };

  // 跳转新闻详细
  const handleNews = (id) => {
    navigate("/article", {
      state: {
        id,
      },
    });
  };

  return (
    <div className={style.news_wrap}>
      <div
        className={style.news_position}
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => handleMouseLeave()}
      >
        <div className={style.news_content}>
          <div className={style.news_img}>
            <img
              src={showData.img}
              style={{ width: "100%", height: "100%" }}
              alt=""
            />
          </div>
          <div className={style.news_content_title}>{showData.title}</div>
          <div className={style.new_content_text}>{showData.profile}</div>
        </div>

        <div className={style.news_list}>
          {dataList.map((item, index) => {
            return (
              <div
                className={[
                  style.news_index,
                  index == current ? style.enlarged : "",
                ].join(" ")}
                key={item.months}
                onMouseEnter={() => handleMouse(index)}
                onClick={() => handleNews(item.id)}
              >
                <div className={style.news_timer}>
                  <div className={style.year}>{item.year}</div>
                  <div className={style.date}>{item.months}</div>
                </div>
                <div className={style.news_profile}>
                  <div className={style.profile_title}>{item.title}</div>
                  <div className={style.profile_content}>{item.profile}</div>
                </div>
              </div>
            );
          })}

          <div className={style.more} onClick={handleMore}>
            MORE
            <DoubleRightOutlined
              style={{
                fontSize: "20px",
                marginLeft: "5px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
