export default [
    {
        id:'9a94b956-7ed8-11ef-a273-033fd029655f',
        year:'2024',
        months:'7-26',
        title:'英思集成携手广东省电子学会共建工程师专业水平评价新标准',
        profile:'2024年7月26日，广东省电子学会与珠海市英思集成电路设计有限公司签署《集成电路产业水平评价合作协议》。',
        img:'/img/article/1727616880574.jpg',
    },
    {
        id: '9a94b958-7ed8-11ef-a273-033fd029655f',
        year:'2024',
        months:'6-21',
        title:'英思芯学V2.0重磅来袭！',
        profile:'英思芯学结合客户过去一年的实践教学经验，于今天正式发布英思芯学实践系统的V2.0版本。',
        img:'/img/article/2-8.jpg',
    },
    {
        id: '9a94b957-7ed8-11ef-a273-033fd029655f',
        year:'2024',
        months:'5-27',
        title:'暨芯研究院—英思集成电路产学研创新基地正式运营！',
        profile:'2024年5月25日，暨芯研究院—英思集成电路产学研创新基地成立仪式在浙江省湖州市吴江区太湖实验室举行。',
        img:'/img/article/3-1.jpg',
    },
    {
        id:'5188258f-7ca7-11ef-a273-033fd029655f',
        year:'2024',
        months:'4-23',
        title:'让学校接轨产业：与广东工业大学实验教学部的实践教学交流',
        profile:'4月22日，珠海市英思集成电路设计有限公司参与了广东省科学院珠海产业技术研究院党支部与广东工业大学实验教学部大学物理实验中心党支部的支部共建活动。',
        img:'/img/article/5-1.jpg',
    },
    {
        id:'51882590-7ca7-11ef-a273-033fd029655f',
        year:'2024',
        months:'4-15',
        title:'暨南大学-英思集成电路产教融合实践教学基地正式开展教学',
        profile:'4月15日，由暨南大学信息科学技术学院电子工程系与珠海市英思集成电路设计有限公司共同建设的集成电路产教融合实践教学基地正式开展集成电路设计教学。',
        img:'/img/article/4-1.jpg',
    },   
]